<template>
  <div class="header-navbar navbar-container">
    <div class="max-width">
      <header data-thq="thq-navbar" class="header-navbar-interactive">
        <div class="header-logo1">
          <router-link to="/" class="header-navlink10">
            <img
              alt="PlotPoint Logo"
              src="/external/plotpoint-green-1200w.png"
              class="header-image1"
            />
          </router-link>
        </div>
        <div data-thq="thq-navbar-nav" class="header-desktop-menu">
          <div class="header-links1">
            <router-link to="/products" class="header-navlink11 navbarLink">
              Products
            </router-link>
            <router-link to="/originators" class="header-navlink12 navbarLink">
              Originators
            </router-link>
            <router-link to="/projects" class="header-navlink13 navbarLink">
              Projects
            </router-link>
            <router-link to="/contact" class="header-navlink14 navbarLink">
              Contact Us
            </router-link>
            <router-link
              to="/coming-soon"
              class="header-navlink15 button-secondary button"
            >
              <span>Log in</span>
            </router-link>
            <router-link
              to="/coming-soon"
              class="header-navlink16 button button-primary"
            >
              <span>Get started</span>
            </router-link>
          </div>
        </div>
        <div data-thq="thq-burger-menu" class="header-burger-menu">
          <svg viewBox="0 0 1024 1024" class="header-icon10">
            <path
              d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
            ></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" class="header-mobile-menu">
          <div class="header-nav">
            <div class="header-top">
              <div class="header-logo2">
                <img
                  alt="PlotPoint Logo"
                  src="/external/plotpoint-green-1200w.png"
                  class="header-image2"
                />
              </div>
              <div data-thq="thq-close-menu" class="header-close-menu">
                <svg viewBox="0 0 1024 1024" class="header-icon12">
                  <path
                    d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
                  ></path>
                </svg>
              </div>
            </div>
            <nav class="header-links2">
              <router-link to="/products" class="header-navlink17 navbarLink">
                Products
              </router-link>
              <router-link
                to="/private-credit"
                class="header-navlink18 navbarLink"
              >
                Private Credit
              </router-link>
              <router-link to="/originators" class="header-navlink19 navbarLink">
                Originators
              </router-link>
              <router-link to="/projects" class="header-navlink20 navbarLink">
                Projects
              </router-link>
              <router-link to="/contact" class="header-navlink21 navbarLink">
                Contact
              </router-link>
            </nav>
            <div class="header-buttons">
              <router-link
                to="/coming-soon"
                class="header-navlink22 navbarLink button-secondary button"
              >
                <span class="navbarLink header-text3">Log in</span>
              </router-link>
            </div>
          </div>
          <div>
            <svg viewBox="0 0 950.8571428571428 1024" class="header-icon14">
              <path
                d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"
              ></path></svg
            ><svg viewBox="0 0 877.7142857142857 1024" class="header-icon16">
              <path
                d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
              ></path></svg
            ><svg viewBox="0 0 602.2582857142856 1024" class="header-icon18">
              <path
                d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"
              ></path>
            </svg>
          </div>
        </div>
      </header>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {},
}
</script>

<style scoped>
.header-navbar {
  position: relative;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-scheme-celadon);
}
 
.header-navbar-interactive {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
 
.header-logo1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
 
.header-navlink10 {
  display: contents;
}
 
.header-image1 {
  width: auto;
  height: 70px;
  object-fit: cover;
  text-decoration: none;
}
 
.header-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
 
.header-links1 {
  gap: var(--dl-layout-space-oneandhalfunits);
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
 
.header-navlink11 {
  text-decoration: none;
}
 
.header-navlink12 {
  text-decoration: none;
}
 
.header-navlink13 {
  text-decoration: none;
}
 
.header-navlink14 {
  margin-right: var(--dl-layout-space-unit);
  text-decoration: none;
}
 
.header-navlink15 {
  margin: 0px;
  transition: 0.3s;
  font-family: "Bricolage Grotesque";
  text-decoration: none;
}
 
.header-navlink15:hover {
  background-color: var(--dl-color-scheme-green80);
}
 
.header-navlink16 {
  display: none;
  transition: 0.3s;
  font-family: "Bricolage Grotesque";
  text-decoration: none;
}
 

 
.header-burger-menu {
  display: none;
}
 
.header-icon10 {
  width: var(--dl-layout-size-xsmall);
  height: var(--dl-layout-size-xsmall);
}
 
.header-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  transform: translateX(100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-scheme-celadon);
}
 
.header-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.header-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-layout-space-threeunits);
  justify-content: space-between;
}
 
.header-logo2 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
 
.header-image2 {
  height: 70px;
  object-fit: cover;
}
 
.header-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
 
.header-icon12 {
  width: var(--dl-layout-size-xsmall);
  height: var(--dl-layout-size-xsmall);

}
 
.header-links2 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
 
.header-navlink17 {
  margin-bottom: var(--dl-layout-space-unit);
}
 
.header-navlink18 {
  margin-bottom: var(--dl-layout-space-unit);
  text-decoration: none;
}
 
.header-navlink19 {
  margin-bottom: var(--dl-layout-space-unit);
  text-decoration: none;
}
 
.header-navlink20 {
  margin-bottom: var(--dl-layout-space-unit);
  text-decoration: none;
}
 
.header-navlink21 {
  margin-bottom: var(--dl-layout-space-unit);
  text-decoration: none;
}
 
.header-buttons {
  display: flex;
  margin-top: var(--dl-layout-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
 
.header-navlink22 {
  text-decoration: none;
}
 
.header-icon14 {
  width: var(--dl-layout-size-xsmall);
  height: var(--dl-layout-size-xsmall);
  margin-right: var(--dl-layout-space-twounits);
}
 
.header-icon16 {
  width: var(--dl-layout-size-xsmall);
  height: var(--dl-layout-size-xsmall);
  margin-right: var(--dl-layout-space-twounits);
}
 
.header-icon18 {
  width: var(--dl-layout-size-xsmall);
  height: var(--dl-layout-size-xsmall);
}
 
@media(max-width: 1200px) {
  .header-mobile-menu {
    display: block;
  }
}
 
@media(max-width: 991px) {
  .header-desktop-menu {
    display: none;
  }
  .header-links1 {
    gap: var(--dl-layout-space-unit);
  }
  .header-burger-menu {
    display: flex;
  }
  .header-icon10 {
    width: var(--dl-layout-size-small);
    height: var(--dl-layout-size-small);
  }
}
 
@media(max-width: 767px) {
  .header-links1 {
    display: none;
  }
  .header-burger-menu {
    align-items: center;
    justify-content: center;
  }
  .header-icon10 {
    width: var(--dl-layout-size-small);
    height: var(--dl-layout-size-small);
  }
  .header-icon12 {
    width: var(--dl-layout-size-small);
    height: var(--dl-layout-size-small);
  }
  .header-navlink17 {
    margin-bottom: var(--dl-layout-space-unit);
  }
  .header-navlink18 {
    margin-left: 0;
  }
  .header-navlink19 {
    margin-left: 0;
  }
  .header-navlink20 {
    margin-left: 0;
  }
  .header-navlink21 {
    margin-left: 0;
  }
  .header-navlink22 {
    font-family: Bricolage Grotesque;
  }
  .header-text3 {
    font-family: Bricolage Grotesque;
  }
}
 
@media(max-width: 479px) {
  .header-mobile-menu {
    padding: var(--dl-layout-space-oneandhalfunits);
  }
  .header-navlink17 {
    font-size: 16px;
    font-style: normal;
    font-family: Bricolage Grotesque;
    font-weight: 600;
    line-height: 1.5;
    text-transform: none;
    text-decoration: none;
  }
}
</style>
