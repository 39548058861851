<template>
  <div class="recent-project-cards-cards-container">
    <div class="recent-project-cards-card1">
      <div class="recent-project-cards-info1">
        <span class="projectsCardHeading">
          <slot name="text">
            <!--Default content for text-->
            <div class="recent-project-cards-fragment">
              <span class="recent-project-cards-text37">
                Bridge Loan in Ruislip
              </span>
            </div>
          </slot>
        </span>
        <div class="recent-project-cards-target-amount1">
          <div class="recent-project-cards-row110">
            <span class="recent-project-cards-text11">Amount: </span>
          </div>
          <div class="recent-project-cards-row111">
            <span class="recent-project-cards-text12">£250,000</span>
          </div>
        </div>
        <div class="recent-project-cards-security-type1">
          <div class="recent-project-cards-row112">
            <span class="recent-project-cards-text13">{{ text1123 }}</span>
          </div>
          <div class="recent-project-cards-row113">
            <span class="recent-project-cards-text14">{{ text115 }}</span>
          </div>
        </div>
        <div class="recent-project-cards-ltv1">
          <div class="recent-project-cards-row114">
            <span class="recent-project-cards-text15">{{ text112211 }}</span>
          </div>
          <div class="recent-project-cards-row115">
            <span class="recent-project-cards-text16">37%</span>
          </div>
        </div>
        <div class="recent-project-cards-interest1">
          <div class="recent-project-cards-row116">
            <span class="recent-project-cards-text17">{{ text11211111 }}</span>
          </div>
          <div class="recent-project-cards-row117">
            <span class="recent-project-cards-text18">
              9% p.a
              <span v-html="rawl0sj"></span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="recent-project-cards-card2">
      <div class="recent-project-cards-info2">
        <span class="projectsCardHeading">
          Bridge and Development Loan in Shoreditch
        </span>
        <div class="recent-project-cards-target-amount2">
          <div class="recent-project-cards-row118">
            <span class="recent-project-cards-text20">Amount: </span>
          </div>
          <div class="recent-project-cards-row119">
            <span class="recent-project-cards-text21">£600,000</span>
          </div>
        </div>
        <div class="recent-project-cards-security-type2">
          <div class="recent-project-cards-row120">
            <span class="recent-project-cards-text22">{{ text1123 }}</span>
          </div>
          <div class="recent-project-cards-row121">
            <span class="recent-project-cards-text23">First Charge</span>
          </div>
        </div>
        <div class="recent-project-cards-ltv2">
          <div class="recent-project-cards-row122">
            <span class="recent-project-cards-text24">{{ text112211 }}</span>
          </div>
          <div class="recent-project-cards-row123">
            <span class="recent-project-cards-text25">46%</span>
          </div>
        </div>
        <div class="recent-project-cards-interest2">
          <div class="recent-project-cards-row124">
            <span class="recent-project-cards-text26">{{ text11211111 }}</span>
          </div>
          <div class="recent-project-cards-row125">
            <span class="recent-project-cards-text27">
              10% p.a
              <span v-html="raw01pd"></span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="recent-project-cards-card3">
      <div class="recent-project-cards-info3">
        <span class="projectsCardHeading">Bridge loan in Camden</span>
        <div class="recent-project-cards-target-amount3">
          <div class="recent-project-cards-row126">
            <span class="recent-project-cards-text29">Amount: </span>
          </div>
          <div class="recent-project-cards-row127">
            <span class="recent-project-cards-text30">£315,000</span>
          </div>
        </div>
        <div class="recent-project-cards-security-type3">
          <div class="recent-project-cards-row128">
            <span class="recent-project-cards-text31">{{ text1123 }}</span>
          </div>
          <div class="recent-project-cards-row129">
            <span class="recent-project-cards-text32">First Charge</span>
          </div>
        </div>
        <div class="recent-project-cards-ltv3">
          <div class="recent-project-cards-row130">
            <span class="recent-project-cards-text33">{{ text112211 }}</span>
          </div>
          <div class="recent-project-cards-row131">
            <span class="recent-project-cards-text34">70%</span>
          </div>
        </div>
        <div class="recent-project-cards-interest3">
          <div class="recent-project-cards-row132">
            <span class="recent-project-cards-text35">{{ text11211111 }}</span>
          </div>
          <div class="recent-project-cards-row133">
            <span class="recent-project-cards-text36">
              9% p.a
              <span v-html="rawluwo"></span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RecentProjectCards',
  props: {
    text112211: {
      type: String,
      default: 'LTV:',
    },
    text115: {
      type: String,
      default: 'First Charge',
    },
    text11211111: {
      type: String,
      default: 'Interest',
    },
    rootClassName: String,
    text1123: {
      type: String,
      default: 'Security Type:',
    },
  },
  data() {
    return {
      rawl0sj: ' ',
      raw01pd: ' ',
      rawluwo: ' ',
    }
  },
}
</script>

<style scoped>
.recent-project-cards-cards-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
 
.recent-project-cards-card1 {
  flex: 0 0 auto;
  width: 32%;
  display: flex;
  padding: 20px;
  max-width: 450px;
  min-height: 480px;
  align-items: center;
  border-radius: 36px;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-end;
  background-image: url("/projects/ruislip2-500w.jpg");
}
 
.recent-project-cards-info1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-layout-space-unit);
  padding-left: var(--dl-layout-space-twounits);
  border-radius: 20px;
  padding-right: var(--dl-layout-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-layout-space-unit);
  background-color: var(--dl-color-scheme-white);
}
 
.recent-project-cards-target-amount1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
 
.recent-project-cards-row110 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.recent-project-cards-text11 {
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
}
 
.recent-project-cards-row111 {
  flex: 0 0 auto;
  width: 48%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.recent-project-cards-text12 {
  font-weight: 400;
  line-height: 1.5;
}
 
.recent-project-cards-security-type1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
 
.recent-project-cards-row112 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.recent-project-cards-text13 {
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
}
 
.recent-project-cards-row113 {
  flex: 0 0 auto;
  width: 48%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.recent-project-cards-text14 {
  line-height: 1.5;
}
 
.recent-project-cards-ltv1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
 
.recent-project-cards-row114 {
  flex: 0 0 auto;
  width: 53%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.recent-project-cards-text15 {
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
}
 
.recent-project-cards-row115 {
  flex: 0 0 auto;
  width: 48%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.recent-project-cards-text16 {
  line-height: 1.5;
}
 
.recent-project-cards-interest1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
 
.recent-project-cards-row116 {
  flex: 0 0 auto;
  width: 53%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.recent-project-cards-text17 {
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
}
 
.recent-project-cards-row117 {
  flex: 0 0 auto;
  width: 48%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.recent-project-cards-text18 {
  line-height: 1.5;
}
 
.recent-project-cards-card2 {
  flex: 0 0 auto;
  width: 32%;
  display: flex;
  padding: 20px;
  max-width: 450px;
  min-height: 480px;
  align-items: center;
  border-radius: 36px;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-end;
  background-image: url("/common/shoreditch2-500h.jpg");
}
 
.recent-project-cards-info2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-layout-space-unit);
  padding-left: var(--dl-layout-space-twounits);
  border-radius: 20px;
  padding-right: var(--dl-layout-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-layout-space-unit);
  background-color: var(--dl-color-scheme-white);
}
 
.recent-project-cards-target-amount2 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
 
.recent-project-cards-row118 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.recent-project-cards-text20 {
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
}
 
.recent-project-cards-row119 {
  flex: 0 0 auto;
  width: 48%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.recent-project-cards-text21 {
  line-height: 1.5;
}
 
.recent-project-cards-security-type2 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
 
.recent-project-cards-row120 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.recent-project-cards-text22 {
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
}
 
.recent-project-cards-row121 {
  flex: 0 0 auto;
  width: 48%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.recent-project-cards-text23 {
  line-height: 1.5;
}
 
.recent-project-cards-ltv2 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
 
.recent-project-cards-row122 {
  flex: 0 0 auto;
  width: 53%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.recent-project-cards-text24 {
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
}
 
.recent-project-cards-row123 {
  flex: 0 0 auto;
  width: 48%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.recent-project-cards-text25 {
  line-height: 1.5;
}
 
.recent-project-cards-interest2 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
 
.recent-project-cards-row124 {
  flex: 0 0 auto;
  width: 53%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.recent-project-cards-text26 {
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
}
 
.recent-project-cards-row125 {
  flex: 0 0 auto;
  width: 48%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.recent-project-cards-text27 {
  line-height: 1.5;
}
 
.recent-project-cards-card3 {
  flex: 0 0 auto;
  width: 32%;
  display: flex;
  padding: 20px;
  max-width: 450px;
  min-height: 480px;
  align-items: center;
  border-radius: 36px;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-end;
  background-image: url("/common/camden-500h.jpg");
}
 
.recent-project-cards-info3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-layout-space-unit);
  padding-left: var(--dl-layout-space-twounits);
  border-radius: 20px;
  padding-right: var(--dl-layout-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-layout-space-unit);
  background-color: var(--dl-color-scheme-white);
}
 
.recent-project-cards-target-amount3 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
 
.recent-project-cards-row126 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.recent-project-cards-text29 {
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
}
 
.recent-project-cards-row127 {
  flex: 0 0 auto;
  width: 48%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.recent-project-cards-text30 {
  line-height: 1.5;
}
 
.recent-project-cards-security-type3 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
 
.recent-project-cards-row128 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.recent-project-cards-text31 {
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
}
 
.recent-project-cards-row129 {
  flex: 0 0 auto;
  width: 48%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.recent-project-cards-text32 {
  line-height: 1.5;
}
 
.recent-project-cards-ltv3 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
 
.recent-project-cards-row130 {
  flex: 0 0 auto;
  width: 53%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.recent-project-cards-text33 {
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
}
 
.recent-project-cards-row131 {
  flex: 0 0 auto;
  width: 48%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.recent-project-cards-text34 {
  line-height: 1.5;
}
 
.recent-project-cards-interest3 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
 
.recent-project-cards-row132 {
  flex: 0 0 auto;
  width: 53%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.recent-project-cards-text35 {
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
}
 
.recent-project-cards-row133 {
  flex: 0 0 auto;
  width: 48%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.recent-project-cards-text36 {
  line-height: 1.5;
}
 
.recent-project-cards-fragment {
  display: contents;
}
 
.recent-project-cards-text37 {
  display: inline-block;
}
 
@media(max-width: 991px) {
  .recent-project-cards-text11 {
    font-size: 14px;
    line-height: 1.5;
  }
  .recent-project-cards-text12 {
    font-size: 14px;
    line-height: 1.5;
  }
  .recent-project-cards-text13 {
    font-size: 14px;
    line-height: 1.5;
  }
  .recent-project-cards-text14 {
    font-size: 14px;
    line-height: 1.5;
  }
  .recent-project-cards-text15 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
  }
  .recent-project-cards-text16 {
    font-size: 14px;
    line-height: 1.5;
  }
  .recent-project-cards-row116 {
    width: 50%;
  }
  .recent-project-cards-text17 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
  }
  .recent-project-cards-text18 {
    font-size: 14px;
    line-height: 1.5;
  }
  .recent-project-cards-text20 {
    font-size: 14px;
    line-height: 1.5;
  }
  .recent-project-cards-text21 {
    font-size: 14px;
    line-height: 1.5;
  }
  .recent-project-cards-text22 {
    font-size: 14px;
    line-height: 1.5;
  }
  .recent-project-cards-text23 {
    font-size: 14px;
    line-height: 1.5;
  }
  .recent-project-cards-text24 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
  }
  .recent-project-cards-text25 {
    font-size: 14px;
    line-height: 1.5;
  }
  .recent-project-cards-row124 {
    width: 50%;
  }
  .recent-project-cards-text26 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
  }
  .recent-project-cards-text27 {
    font-size: 14px;
    line-height: 1.5;
  }
  .recent-project-cards-text29 {
    font-size: 14px;
    line-height: 1.5;
  }
  .recent-project-cards-text30 {
    font-size: 14px;
    line-height: 1.5;
  }
  .recent-project-cards-text31 {
    font-size: 14px;
    line-height: 1.5;
  }
  .recent-project-cards-text32 {
    font-size: 14px;
    line-height: 1.5;
  }
  .recent-project-cards-text33 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
  }
  .recent-project-cards-text34 {
    font-size: 14px;
    line-height: 1.5;
  }
  .recent-project-cards-row132 {
    width: 50%;
  }
  .recent-project-cards-text35 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
  }
  .recent-project-cards-text36 {
    font-size: 14px;
    line-height: 1.5;
  }
}
 
@media(max-width: 767px) {
  .recent-project-cards-cards-container {
    gap: var(--dl-layout-space-oneandhalfunits);
    flex-wrap: wrap;
    justify-content: center;
  }
  .recent-project-cards-card1 {
    width: 100%;
  }
  .recent-project-cards-row114 {
    width: 50%;
  }
  .recent-project-cards-card2 {
    width: 100%;
  }
  .recent-project-cards-row122 {
    width: 50%;
  }
  .recent-project-cards-card3 {
    width: 100%;
  }
  .recent-project-cards-row130 {
    width: 50%;
  }
}
</style>
