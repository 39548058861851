<template>
  <div class="products-container10">
    <app-header></app-header>
    <div class="products-hero hero-container section-container">
      <div class="products-max-width1 max-width">
        <div class="products-content4">
          <span class="products-subtitle subtitle-top">OUR PRODUCTS</span>
          <h1 class="products-title1">
            <span>A range of funding products for </span>
            <span class="products-text11">every project</span>
          </h1>
          <span class="products-description">
            <span>
              PlotPoint offers sophisticated funders the opportunity to invest in
              secured private credit deals. Our platform connects you with
              experienced originators and property-backed investments that deliver
              strong returns while maintaining transparency and security.
            </span>
            <br />
          </span>
        </div>
        <div class="products-image1">
          <img
            alt="image"
            src="/products/homes1-1200w.jpg"
            class="products-hero-image1"
          />
          <img
            alt="image"
            src="/common/group%2018-1200w.png"
            class="products-image2"
          />
        </div>
      </div>
    </div>
    <div class="products-section1 section-container">
      <div class="products-max-width2 max-width">
        <div class="products-max-width3 thq-section-max-width">
          <span class="beforeHeading products-text14">
            Tailored Finance Solutions for Every Need
          </span>
          <h1 class="products-text15">
            <span>Our </span>
            <span class="products-text17">Funding Products</span>
          </h1>
          <div class="products-container11 thq-grid-3">
            <div class="products-container12 thq-card">
              <img
                alt="Asset-Backed Loans Image Alt Text"
                src="/home/bridgeicon-200h.png"
                class="homeproductsimg"
              />
              <span class="products-text18 beforeHeading">
                ORIGINATORS &amp; iNTERMEDIARIES
              </span>
              <h2 class="products-text19 thq-heading-2">Bridging</h2>
              <span class="products-text20 thq-body-small">
                For developers needing quick, interim funding, our simple bridging
                loans offer flexible short-term capital to cover financing gaps.
              </span>
            </div>
            <div class="products-container13 thq-card">
              <img
                alt="Asset-Backed Loans Image Alt Text"
                src="/home/refurbicon-200h.png"
                class="products-image4 homeproductsimg"
              />
              <span class="products-text21 beforeHeading">
                ORIGINATORS &amp; iNTERMEDIARIES
              </span>
              <h2 class="products-text22 thq-heading-2">Refurbishments</h2>
              <span class="products-text23 thq-body-small">
                For developers revitalising properties, our refurbishment loans
                provide tailored funding for projects of any scale — from light
                updates to heavy transformations.
              </span>
            </div>
            <div class="products-container14 thq-card">
              <img
                alt="Asset-Backed Loans Image Alt Text"
                src="/home/developmenticon-200h.png"
                class="products-image5 homeproductsimg"
              />
              <span class="products-text24 beforeHeading">Intermediaries</span>
              <h2 class="products-text25 thq-heading-2">Development</h2>
              <span class="products-text26 thq-body-small">
                For experienced SME developers with ambitious projects, our
                development loans provide reliable, flexible financing to support
                every phase of your project, from planning to completion.
              </span>
            </div>
          </div>
          <div class="products-container15">
            <router-link
              to="/contact"
              class="products-navlink1 button button-gradient"
            >
              Get a Quote
            </router-link>
            <router-link
              to="/book-a-call"
              class="products-navlink2 button button-transparent"
            >
              Book a call
            </router-link>
          </div>
          <span class="products-link13 thq-body-small">
            Loan products are subject to credit approval. Failure to maintain
            repayments may result in repossession of the secured asset. Terms and
            conditions apply.
          </span>
        </div>
      </div>
    </div>
    <div class="products-section2 section-container">
      <div class="products-max-width4 max-width">
        <div class="products-content5">
          <span class="products-text27">our track record</span>
          <h1 class="products-text28">
            <span class="products-text29">Over </span>
            <span class="products-text30">
              Two Decades
              <span v-html="rawhg1o"></span>
            </span>
            <span class="products-text31">of Combined Experience</span>
          </h1>
          <div class="products-cards thq-section-padding">
            <div
              class="products-max-width5 thq-grid-auto-300 thq-section-max-width"
            >
              <div class="products-container16 thq-flex-column thq-card">
                <h2 class="products-text32 thq-heading-2">£100 million+</h2>
                <span class="products-text33 thq-body-small">
                  Capital Deployed
                </span>
              </div>
              <div class="products-container17 thq-flex-column thq-card">
                <h2 class="products-text34 thq-heading-2">100</h2>
                <span class="products-text35 thq-body-small">
                  Completed Deals
                </span>
              </div>
              <div class="products-container18 thq-flex-column thq-card">
                <h2 class="products-text36 thq-heading-2">9.2%</h2>
                <span class="products-text37 thq-body-small">
                  Avg. Gross Interest Rate p.a
                </span>
              </div>
              <div class="products-container19 thq-flex-column thq-card">
                <h2 class="products-text38 thq-heading-2">40+ Years</h2>
                <span class="products-text39 thq-body-small">
                  of Wealth Management Experience
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="products-section3 section-container">
      <div class="products-max-width6 max-width">
        <div class="products-content6">
          <span class="beforeHeading products-text40">
            Clear, transparent terms
          </span>
          <h1 class="products-text41">
            <span>Our </span>
            <span class="products-text43">Criteria</span>
          </h1>
          <ul class="products-ul list">
            <li class="products-li1 list-item">
              <span class="products-text44">
                Financing of up to £1 million per deal
              </span>
            </li>
            <li class="products-li2 list-item">
              <span class="products-text45">
                Interest Rates starting at 0.80% per month
              </span>
            </li>
            <li class="products-li3 list-item">
              <span class="products-text46">Loan to Values up to 75% </span>
            </li>
            <li class="products-li4 list-item">
              <span>
                <span class="products-text48">Flexible Terms</span>
                <br />
              </span>
            </li>
            <li class="products-li5 list-item">
              <span class="products-text50">No exit fees</span>
            </li>
          </ul>
          <a
            href="https://go.plotpoint.co.uk/ppproductguide"
            target="_blank"
            rel="noreferrer noopener"
            class="products-link1 button button-transparent"
          >
            Download our Product Guide
          </a>
        </div>
        <div class="products-image6">
          <img
            alt="image"
            src="/products/criteria-1200w.jpg"
            class="products-hero-image2"
          />
        </div>
      </div>
    </div>
    <div class="products-section4 section-container">
      <div class="products-max-width7 max-width">
        <div class="products-image7">
          <img
            alt="image"
            src="/partnerwithus/originator1-1200w.jpg"
            class="products-hero-image3"
          />
        </div>
        <div class="products-content7">
          <span class="subtitle-top">EFFORTLESS, QUICK DECISIONS</span>
          <h1 class="products-text52">Apply in Three Simple steps</h1>
          <div class="products-step1">
            <div class="products-number1">
              <span class="products-text53">1</span>
            </div>
            <div class="products-container20">
              <span class="steps-heading">Consult with Our Team</span>
              <span class="products-text54">
                Speak with one of our dedicated experts who will assess your needs
                and guide you through the process.
              </span>
            </div>
          </div>
          <div class="products-step2">
            <div class="products-number2">
              <span class="products-text55">2</span>
            </div>
            <div class="products-container21">
              <span class="products-title3 steps-heading">
                Complete a Simple Application
              </span>
              <span class="products-text56">
                <span>
                  Fill out our streamlined form with essential details for a fast,
                  tailored review.
                </span>
                <br />
              </span>
            </div>
          </div>
          <div class="products-step3">
            <div class="products-number3">
              <span class="products-text59">3</span>
            </div>
            <div class="products-container22">
              <span class="products-title4 steps-heading">
                Receive a Swift Decision
              </span>
              <span class="products-text60">
                Once you&apos;ve selected a deal, you transfer your funds to a
                solicitor client account, and you can monitor your deal&apos;s
                performance and project updates through our dashboard.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <case-study rootClassName="case-studyroot-class-name">
      <template v-slot:text>
        <div class="products-fragment10">
          <span class="products-text61">FEATURED PROJECT</span>
        </div>
      </template>
      <template v-slot:text1>
        <div class="products-fragment11">
          <span class="products-text62">
            Partnering with the borrower, we recently finalized a £600,000
            three-tranche, asset-backed bridge and development loan in Shoreditch,
            London.
          </span>
        </div>
      </template>
      <template v-slot:text2>
        <div class="products-fragment12">
          <span class="products-text63">Gross Interest Rate p.a</span>
        </div>
      </template>
      <template v-slot:text3>
        <div class="products-fragment13">
          <span class="products-text64">Loan Term</span>
        </div>
      </template>
      <template v-slot:text4>
        <div class="products-fragment14">
          <span class="products-text65">Loan to Value (LTV)</span>
        </div>
      </template>
      <template v-slot:text5>
        <div class="products-fragment15">
          <span class="products-text66">Secured Asset-Backed Lending</span>
        </div>
      </template>
      <template v-slot:heading>
        <div class="products-fragment16">
          <span class="products-text67">
            <span>
              Bridge Loan in
              <span v-html="rawufur"></span>
            </span>
            <span class="products-text69">Shoreditch</span>
          </span>
        </div>
      </template>
      <template v-slot:heading1>
        <div class="products-fragment17">
          <span class="products-text70">10%</span>
        </div>
      </template>
      <template v-slot:heading2>
        <div class="products-fragment18">
          <span class="products-text71">11 Months</span>
        </div>
      </template>
      <template v-slot:heading3>
        <div class="products-fragment19">
          <span class="products-text72">46%</span>
        </div>
      </template>
      <template v-slot:heading4>
        <div class="products-fragment20">
          <span class="products-text73">First Charge and Debenture</span>
        </div>
      </template>
    </case-study>
    <div class="products-section5 section-container">
      <div class="products-max-width8 max-width">
        <div class="products-banner">
          <span class="products-text74 beforeHeading">
             Expert guidance at every step
          </span>
          <h1 class="products-text75">Speak to your Dedicated Manager</h1>
          <span class="products-text76">
            Connect with your personal account manager today for tailored advice
            and support. Our expert team is here to answer your questions, guide
            you through our products, and ensure your project receives the
            attention it deserves.
          </span>
          <img
            alt="image"
            src="/common/group%2018-1200w.png"
            class="products-image8"
          />
          <img
            alt="image"
            src="/common/group%2018-1200w.png"
            class="products-image9"
          />
          <div class="products-btns">
            <router-link
              to="/contact"
              class="products-navlink3 button button-transparent"
            >
              <span class="products-text77">Get a Quote</span>
            </router-link>
            <router-link
              to="/book-a-call"
              class="products-navlink4 button button-gradient"
            >
              <span class="products-text78">Book a call</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <app-footer>
      <template v-slot:text>
        <div class="products-fragment21">
          <span class="products-text79">About Us</span>
        </div>
      </template>
      <template v-slot:text1>
        <div class="products-fragment22">
          <span class="products-text80">About us</span>
        </div>
      </template>
      <template v-slot:text2>
        <div class="products-fragment23">
          <span class="products-text81">Private Credit</span>
        </div>
      </template>
      <template v-slot:text3>
        <div class="products-fragment24">
          <span class="products-text82">Partner with Us</span>
        </div>
      </template>
      <template v-slot:text5>
        <div class="products-fragment25">
          <span class="products-text83">Contact</span>
        </div>
      </template>
      <template v-slot:text6>
        <div class="products-fragment26">
          <span class="products-text84">App</span>
        </div>
      </template>
      <template v-slot:text7>
        <div class="products-fragment27">
          <span class="products-text85">Download iOS app</span>
        </div>
      </template>
      <template v-slot:text8>
        <div class="products-fragment28">
          <span class="products-text86">Download Android app</span>
        </div>
      </template>
      <template v-slot:text9>
        <div class="products-fragment29">
          <span class="products-text87">Originator Portal</span>
        </div>
      </template>
      <template v-slot:link13>
        <div class="products-fragment30">
          <span class="products-text88">Your Capital is at Risk</span>
        </div>
      </template>
      <template v-slot:text10>
        <div class="products-fragment31">
          <span class="products-text89">Resources</span>
        </div>
      </template>
      <template v-slot:text11>
        <div class="products-fragment32">
          <span class="products-text90">Funder Terms and Conditions</span>
        </div>
      </template>
      <template v-slot:text13>
        <div class="products-fragment33">
          <span class="products-text91">Disclaimer</span>
        </div>
      </template>
      <template v-slot:text14>
        <div class="products-fragment34">
          <span class="products-text92">Privacy Policy</span>
        </div>
      </template>
      <template v-slot:text15>
        <div class="products-fragment35">
          <span class="products-text93">Products</span>
        </div>
      </template>
      <template v-slot:content1>
        <div class="products-fragment36">
          <span class="products-content1">
            <span>Fourth Floor, 70 Baker Street, </span>
            <span>London, W1U 7DL</span>
            <br />
          </span>
        </div>
      </template>
      <template v-slot:content2>
        <div class="products-fragment37">
          <span class="products-content2">
            <span class="products-text97">Phone: </span>
            <a href="tel:+442081547035" class="products-link2">+</a>
            <a href="tel:+442081547035" class="products-link3">44 208 154 7035</a>
            <br />
          </span>
        </div>
      </template>
      <template v-slot:content3>
        <div class="products-fragment38">
          <span class="products-content3">
            <span class="products-text99">
              ©2024 PlotPoint Limited. All rights reserved.
            </span>
            <br />
            <span>
              Registered in England &amp; Wales (Reg Number: 15914598).
              <span v-html="rawr70g"></span>
            </span>
          </span>
        </div>
      </template>
      <template v-slot:contentTitle1>
        <div class="products-fragment39">
          <span class="products-text102">PlotPoint Limited</span>
        </div>
      </template>
    </app-footer>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import CaseStudy from '../components/case-study'
import AppFooter from '../components/footer'

export default {
  name: 'Products',
  props: {},
  components: {
    AppHeader,
    CaseStudy,
    AppFooter,
  },
  data() {
    return {
      rawhg1o: ' ',
      rawufur: ' ',
      rawr70g: ' ',
    }
  },
  metaInfo: {
    title: 'Bridging and Development Financing - PlotPoint',
    meta: [
      {
        name: 'description',
        content:
          "Explore PlotPoint's flexible funding solutions—bridging, refurbishment, development, and mezzanine loans—with quick decisions and expert support.",
      },
      {
        property: 'og:title',
        content: 'Bridging and Development Financing - PlotPoint',
      },
      {
        property: 'og:description',
        content:
          "Explore PlotPoint's flexible funding solutions—bridging, refurbishment, development, and mezzanine loans—with quick decisions and expert support.",
      },
    ],
  },
}
</script>

<style scoped>
.products-container10 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
 
.products-hero {
  padding-top: 0px;
  padding-bottom: 0px;
}
 
.products-max-width1 {
  align-items: flex-start;
  padding-top: 0px;
  padding-bottom: 0px;
}
 
.products-content4 {
  flex: 0 0 auto;
  width: 45%;
  display: flex;
  margin-top: var(--dl-layout-space-twounits);
  align-items: flex-start;
  margin-right: var(--dl-layout-space-twounits);
  margin-bottom: var(--dl-layout-space-threeunits);
  flex-direction: column;
}
 
.products-subtitle {
  color: var(--dl-color-scheme-white);
  font-size: 14px;
  font-style: normal;
  font-family: "Urbanist";
  font-weight: 800;
  letter-spacing: 0.12em;
  text-transform: uppercase;
}
 
.products-title1 {
  color: var(--dl-color-scheme-white);
  font-size: 52px;
  font-style: normal;
  margin-top: var(--dl-layout-space-halfunit);
  font-family: "Bricolage Grotesque";
  font-weight: 600;
  line-height: 1;
  margin-bottom: var(--dl-layout-space-unit);
}
 
.products-text11 {
  color: var(--dl-color-scheme-celadon);
}
 
.products-description {
  color: var(--dl-color-scheme-white);
  font-size: 18px;
  font-family: "Bricolage Grotesque";
  line-height: 1.5;
  padding-bottom: var(--dl-layout-space-unit);
}
 
.products-image1 {
  padding: 0px;
  position: relative;
}
 
.products-hero-image1 {
  flex: 1;
  height: auto;
  max-width: 550px;
  object-fit: cover;
  border-radius: 48px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
 
.products-image2 {
  top: -1px;
  right: -4px;
  position: absolute;
  object-fit: contain;
}
 
.products-section1 {
  padding-top: var(--dl-layout-space-unit);
  padding-bottom: var(--dl-layout-space-unit);
}
 
.products-max-width2 {
  align-items: stretch;
}
 
.products-max-width3 {
  gap: 0;
  display: flex;
  align-items: center;
  padding-top: var(--dl-layout-space-twounits);
  flex-direction: column;
}
 
.products-text15 {
  font-size: 42px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-layout-space-unit);
}
 
.products-text17 {
  color: var(--dl-color-scheme-medgreen);
}
 
.products-container11 {
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}
 
.products-container12 {
  gap: 0;
  flex: 1;
  height: auto;
  display: flex;
  align-self: flex-start;
  box-shadow: 2px 2px 4px 0px #d4d4d4;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-accent1);
}
 
.products-text18 {
  margin-top: var(--dl-layout-space-unit);
  text-align: center;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
}
 
.products-text19 {
  text-align: center;
}
 
.products-text20 {
  text-align: center;
}
 
.products-container13 {
  gap: 0;
  flex: 1;
  height: auto;
  display: flex;
  align-self: flex-start;
  box-shadow: 2px 2px 4px 0px #d4d4d4;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-accent1);
}
 
.products-image4 {
  width: var(--dl-layout-size-large);
  height: var(--dl-layout-size-large);
  object-fit: cover;
}
 
.products-text21 {
  margin-top: var(--dl-layout-space-unit);
  text-align: center;
}
 
.products-text22 {
  text-align: center;
}
 
.products-text23 {
  text-align: center;
}
 
.products-container14 {
  gap: 0;
  flex: 1;
  height: auto;
  display: flex;
  align-self: flex-start;
  box-shadow: 2px 2px 4px 0px #d4d4d4;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-accent1);
}
 
.products-image5 {
  width: var(--dl-layout-size-large);
  height: var(--dl-layout-size-large);
  object-fit: cover;
}
 
.products-text24 {
  margin-top: var(--dl-layout-space-unit);
  text-align: center;
}
 
.products-text25 {
  text-align: center;
}
 
.products-text26 {
  text-align: center;
}
 
.products-container15 {
  flex: 0 0 auto;
  display: flex;
  align-items: stretch;
  padding-top: var(--dl-layout-space-unit);
  flex-direction: row;
  padding-bottom: var(--dl-layout-space-unit);
  justify-content: center;
}
 
.products-navlink1 {
  transition: 0.3s;
  text-decoration: none;
}
 
.products-navlink1:hover {
  fill: var(--dl-color-scheme-green100);
  color: var(--dl-color-scheme-green100);
  border-color: var(--dl-color-scheme-green80);
}
 
.products-navlink2 {
  fill: var(--dl-color-scheme-green80);
  color: var(--dl-color-scheme-green80);
  border-color: var(--dl-color-scheme-green80);
  text-decoration: none;
}
 
.products-link13 {
  font-size: 12px;
  font-style: normal;
  text-align: center;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
}
 
.products-section2 {
  padding-top: var(--dl-layout-space-fourunits);
  padding-bottom: var(--dl-layout-space-fourunits);
  background-color: var(--dl-color-scheme-green100);
}
 
.products-max-width4 {
  align-items: stretch;
}
 
.products-content5 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: var(--dl-layout-space-twounits);
  flex-direction: column;
  justify-content: center;
}
 
.products-text27 {
  color: var(--dl-color-scheme-white);
  font-size: 14px;
  font-style: normal;
  font-family: "Urbanist";
  font-weight: 800;
  line-height: 1.15;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  text-decoration: none;
}
 
.products-text28 {
  font-size: 42px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-layout-space-unit);
}
 
.products-text29 {
  color: var(--dl-color-scheme-white);
}
 
.products-text30 {
  color: var(--dl-color-scheme-celadon);
}
 
.products-text31 {
  color: var(--dl-color-scheme-white);
}
 
.products-cards {
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  padding-top: var(--dl-layout-space-unit);
  padding-left: var(--dl-layout-space-unit);
  padding-right: var(--dl-layout-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-layout-space-unit);
}
 
.products-max-width5 {
  display: flex;
  align-items: center;
}
 
.products-container16 {
  gap: 0;
  color: var(--dl-color-theme-neutral-light);
  width: 25%;
  height: var(--dl-layout-size-large);
  display: flex;
  align-self: flex-start;
  align-items: center;
  padding-left: var(--dl-layout-space-unit);
  padding-right: var(--dl-layout-space-unit);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-honeydew);
}
 
.products-text32 {
  color: var(--dl-color-scheme-green80);
  height: 55px;
  font-size: 32px;
  text-align: center;
}
 
.products-text33 {
  color: var(--dl-color-theme-neutral-dark);
  font-size: 12px;
  font-family: "Bricolage Grotesque";
}
 
.products-container17 {
  gap: 0;
  color: var(--dl-color-theme-neutral-light);
  width: 25%;
  height: var(--dl-layout-size-large);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-honeydew);
}
 
.products-text34 {
  color: var(--dl-color-scheme-green80);
  height: 55px;
}
 
.products-text35 {
  color: var(--dl-color-theme-neutral-dark);
  font-size: 12px;
  font-family: "Bricolage Grotesque";
}
 
.products-container18 {
  gap: 0;
  color: var(--dl-color-theme-neutral-light);
  width: 25%;
  height: var(--dl-layout-size-large);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-honeydew);
}
 
.products-text36 {
  color: var(--dl-color-scheme-green80);
}
 
.products-text37 {
  color: var(--dl-color-theme-neutral-dark);
  font-size: 12px;
  font-family: "Bricolage Grotesque";
}
 
.products-container19 {
  gap: 0;
  color: var(--dl-color-theme-neutral-light);
  width: 25%;
  height: var(--dl-layout-size-large);
  display: flex;
  align-self: flex-start;
  align-items: center;
  padding-left: var(--dl-layout-space-unit);
  padding-right: var(--dl-layout-space-unit);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-honeydew);
}
 
.products-text38 {
  color: var(--dl-color-scheme-green80);
}
 
.products-text39 {
  color: var(--dl-color-theme-neutral-dark);
  font-size: 12px;
  font-family: "Bricolage Grotesque";
}
 
.products-section3 {
  padding-top: var(--dl-layout-space-fourunits);
  padding-bottom: var(--dl-layout-space-fourunits);
  background-color: var(--dl-color-scheme-honeydew);
}
 
.products-max-width6 {
  align-items: stretch;
}
 
.products-content6 {
  width: 60%;
  display: flex;
  margin-right: var(--dl-layout-space-twounits);
  flex-direction: column;
  justify-content: center;
}
 
.products-text41 {
  font-size: 42px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-layout-space-unit);
}
 
.products-text43 {
  color: var(--dl-color-scheme-medgreen);
}
 
.products-ul {
  margin-top: var(--dl-layout-space-halfunit);
  margin-bottom: 0px;
  padding-bottom: 0px;
  list-style-type: square;
}
 
.products-li1 {
  padding-bottom: var(--dl-layout-space-unit);
}
 
.products-text44 {
  font-weight: bold;
}
 
.products-li2 {
  margin-bottom: var(--dl-layout-space-unit);
}
 
.products-text45 {
  font-weight: bold;
}
 
.products-li3 {
  margin-bottom: var(--dl-layout-space-unit);
}
 
.products-text46 {
  font-weight: bold;
}
 
.products-li4 {
  margin-bottom: var(--dl-layout-space-unit);
}
 
.products-text48 {
  font-weight: 700;
}
 
.products-li5 {
  margin-bottom: var(--dl-layout-space-unit);
}
 
.products-text50 {
  font-weight: bold;
}
 
.products-link1 {
  fill: var(--dl-color-scheme-green80);
  color: var(--dl-color-scheme-green80);
  width: auto;
  align-self: flex-start;
  text-align: center;
  border-color: var(--dl-color-scheme-green80);
  text-decoration: none;
}
 
.products-hero-image2 {
  width: 600px;
  height: 400px;
  object-fit: cover;
  border-radius: 48px;
}
 
.products-section4 {
  padding-top: var(--dl-layout-space-fourunits);
  padding-bottom: var(--dl-layout-space-fourunits);
  background-color: var(--dl-color-scheme-green80);
}
 
.products-max-width7 {
  align-items: stretch;
}
 
.products-image7 {
  width: 50%;
  align-self: center;
}
 
.products-hero-image3 {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 48px;
}
 
.products-content7 {
  width: 40%;
  display: flex;
  margin-top: 0px;
  margin-bottom: 0px;
  flex-direction: column;
  justify-content: center;
}
 
.products-text52 {
  color: var(--dl-color-scheme-white);
}
 
.products-step1 {
  display: flex;
  margin-top: var(--dl-layout-space-twounits);
  margin-bottom: var(--dl-layout-space-twounits);
  flex-direction: row;
}
 
.products-number1 {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-layout-space-twounits);
  border-radius: var(--dl-layout-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-honeydew);
}
 
.products-text53 {
  font-size: 28px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
}
 
.products-container20 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.products-text54 {
  color: var(--dl-color-scheme-white);
}
 
.products-step2 {
  display: flex;
  margin-bottom: var(--dl-layout-space-twounits);
  flex-direction: row;
}
 
.products-number2 {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-layout-space-twounits);
  border-radius: var(--dl-layout-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-honeydew);
}
 
.products-text55 {
  font-size: 28px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
}
 
.products-container21 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.products-title3 {
  color: var(--dl-color-scheme-white);
}
 
.products-text56 {
  color: var(--dl-color-scheme-white);
}
 
.products-step3 {
  display: flex;
  margin-bottom: var(--dl-layout-space-twounits);
  flex-direction: row;
}
 
.products-number3 {
  flex: 0 0 auto;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-layout-space-twounits);
  border-radius: var(--dl-layout-radius-radius16);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-scheme-honeydew);
}
 
.products-text59 {
  font-size: 28px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 700;
  line-height: 1.5;
}
 
.products-container22 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.products-title4 {
  color: var(--dl-color-scheme-white);
}
 
.products-text60 {
  color: var(--dl-color-scheme-white);
  line-height: 1.5;
}
 
.products-fragment10 {
  display: contents;
}
 
.products-text61 {
  display: inline-block;
}
 
.products-fragment11 {
  display: contents;
}
 
.products-text62 {
  display: inline-block;
}
 
.products-fragment12 {
  display: contents;
}
 
.products-text63 {
  display: inline-block;
}
 
.products-fragment13 {
  display: contents;
}
 
.products-text64 {
  display: inline-block;
}
 
.products-fragment14 {
  display: contents;
}
 
.products-text65 {
  display: inline-block;
}
 
.products-fragment15 {
  display: contents;
}
 
.products-text66 {
  display: inline-block;
}
 
.products-fragment16 {
  display: contents;
}
 
.products-text67 {
  display: inline-block;
  font-size: 42px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
}
 
.products-text69 {
  color: var(--dl-color-scheme-white);
}
 
.products-fragment17 {
  display: contents;
}
 
.products-text70 {
  display: inline-block;
}
 
.products-fragment18 {
  display: contents;
}
 
.products-text71 {
  display: inline-block;
}
 
.products-fragment19 {
  display: contents;
}
 
.products-text72 {
  display: inline-block;
}
 
.products-fragment20 {
  display: contents;
}
 
.products-text73 {
  display: inline-block;
}
 
.products-section5 {
  position: relative;
  padding-top: var(--dl-layout-space-twounits);
  padding-bottom: var(--dl-layout-space-twounits);
}
 
.products-max-width8 {
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.products-banner {
  clip: auto;
  flex: 0 0 auto;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-layout-space-fourunits);
  padding-left: var(--dl-layout-space-fiveunits);
  border-radius: 48px;
  padding-right: var(--dl-layout-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-layout-space-fourunits);
  background-color: var(--dl-color-scheme-green100);
}
 
.products-text74 {
  color: var(--dl-color-scheme-honeydew);
  margin-bottom: var(--dl-layout-space-halfunit);
}
 
.products-text75 {
  color: var(--dl-color-scheme-white);
  z-index: 100;
  font-size: 42px;
  font-style: normal;
  text-align: center;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: var(--dl-layout-space-halfunit);
}
 
.products-text76 {
  fill: var(--dl-color-scheme-white);
  color: var(--dl-color-scheme-white);
  width: auto;
  z-index: 100;
  text-align: center;
  padding-left: 0px;
  margin-bottom: var(--dl-layout-space-twounits);
  padding-right: 0px;
}
 
.products-image8 {
  top: 35px;
  right: 3px;
  width: 195px;
  height: 275px;
  opacity: 0.4;
  z-index: 1;
  position: absolute;
  object-fit: contain;
}
 
.products-image9 {
  top: 19px;
  left: 2px;
  width: 195px;
  height: 275px;
  opacity: 0.4;
  z-index: 1;
  position: absolute;
  transform: rotate(180deg);
  object-fit: contain;
}
 
.products-btns {
  flex: 0 0 auto;
  display: flex;
  align-items: stretch;
  flex-direction: row;
}
 
.products-navlink3 {
  font-size: 16px;
  text-decoration: none;
}
 
.products-navlink4 {
  text-align: center;
  margin-left: var(--dl-layout-space-twounits);
  text-decoration: none;
}
 
.products-fragment21 {
  display: contents;
}
 
.products-text79 {
  display: inline-block;
}
 
.products-fragment22 {
  display: contents;
}
 
.products-text80 {
  display: inline-block;
}
 
.products-fragment23 {
  display: contents;
}
 
.products-text81 {
  display: inline-block;
}
 
.products-fragment24 {
  display: contents;
}
 
.products-text82 {
  display: inline-block;
}
 
.products-fragment25 {
  display: contents;
}
 
.products-text83 {
  display: inline-block;
}
 
.products-fragment26 {
  display: contents;
}
 
.products-text84 {
  display: inline-block;
}
 
.products-fragment27 {
  display: contents;
}
 
.products-text85 {
  display: inline-block;
}
 
.products-fragment28 {
  display: contents;
}
 
.products-text86 {
  display: inline-block;
}
 
.products-fragment29 {
  display: contents;
}
 
.products-text87 {
  display: inline-block;
}
 
.products-fragment30 {
  display: contents;
}
 
.products-text88 {
  display: inline-block;
}
 
.products-fragment31 {
  display: contents;
}
 
.products-text89 {
  display: inline-block;
}
 
.products-fragment32 {
  display: contents;
}
 
.products-text90 {
  display: inline-block;
}
 
.products-fragment33 {
  display: contents;
}
 
.products-text91 {
  display: inline-block;
}
 
.products-fragment34 {
  display: contents;
}
 
.products-text92 {
  display: inline-block;
}
 
.products-fragment35 {
  display: contents;
}
 
.products-text93 {
  display: inline-block;
}
 
.products-fragment36 {
  display: contents;
}
 
.products-content1 {
  display: inline-block;
}
 
.products-fragment37 {
  display: contents;
}
 
.products-content2 {
  display: inline-block;
}
 
.products-text97 {
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
}
 
.products-link2 {
  text-decoration: underline;
}
 
.products-link3 {
  text-decoration: underline;
}
 
.products-fragment38 {
  display: contents;
}
 
.products-content3 {
  display: inline-block;
}
 
.products-text99 {
  font-weight: 700;
}
 
.products-fragment39 {
  display: contents;
}
 
.products-text102 {
  display: inline-block;
}
 
@media(max-width: 1200px) {
  .products-text11 {
    color: var(--dl-color-scheme-celadon);
  }
  .products-text17 {
    color: var(--dl-color-scheme-celadon);
  }
  .products-text30 {
    color: var(--dl-color-scheme-celadon);
  }
  .products-text31 {
    color: var(--dl-color-scheme-celadon);
  }
  .products-text41 {
    font-family: Bricolage Grotesque;
  }
  .products-text43 {
    color: var(--dl-color-scheme-medgreen);
  }
  .products-text67 {
    font-size: 42px;
    font-style: normal;
    font-family: Bricolage Grotesque;
    font-weight: 700;
    line-height: 1.25;
  }
}
 
@media(max-width: 991px) {
  .products-content4 {
    width: 50%;
    margin-right: var(--dl-layout-space-unit);
  }
  .products-image1 {
    padding-bottom: var(--dl-layout-space-threeunits);
  }
  .products-hero-image1 {
    margin-bottom: 0px;
  }
  .products-max-width2 {
    flex-direction: column;
  }
  .products-max-width3 {
    gap: var(--dl-layout-space-unit);
    flex-direction: column;
  }
  .products-text14 {
    text-align: center;
  }
  .products-text15 {
    text-align: center;
  }
  .products-container11 {
    grid-template-columns: 1fr;
  }
  .products-text18 {
    width: auto;
    align-self: center;
  }
  .products-text21 {
    width: auto;
    align-self: center;
  }
  .products-text24 {
    width: auto;
    align-self: center;
  }
  .products-max-width4 {
    flex-direction: column;
  }
  .products-content5 {
    width: 100%;
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
  }
  .products-text27 {
    text-align: center;
  }
  .products-text28 {
    text-align: center;
  }
  .products-max-width5 {
    gap: var(--dl-layout-space-twounits);
    flex-direction: row;
  }
  .products-container16 {
    height: var(--dl-layout-size-medium);
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .products-text32 {
    height: auto;
    font-size: 24px;
  }
  .products-container17 {
    height: var(--dl-layout-size-medium);
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .products-text34 {
    height: auto;
    font-size: 24px;
  }
  .products-container18 {
    height: var(--dl-layout-size-medium);
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .products-text36 {
    font-size: 24px;
  }
  .products-text37 {
    font-size: 10px;
  }
  .products-container19 {
    height: var(--dl-layout-size-medium);
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .products-text38 {
    font-size: 24px;
  }
  .products-text39 {
    text-align: center;
  }
  .products-section3 {
    align-items: center;
  }
  .products-max-width6 {
    flex-direction: column;
  }
  .products-content6 {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-layout-space-threeunits);
  }
  .products-text40 {
    width: auto;
    align-self: center;
  }
  .products-text41 {
    text-align: center;
  }
  .products-ul {
    width: auto;
    align-self: center;
  }
  .products-link1 {
    align-self: center;
  }
  .products-image6 {
    padding-bottom: 0px;
  }
  .products-hero-image2 {
    width: 100%;
    height: 300px;
    margin-left: 0px;
    margin-bottom: 0px;
  }
  .products-max-width7 {
    flex-direction: column;
  }
  .products-image7 {
    width: 100%;
    padding-bottom: var(--dl-layout-space-threeunits);
  }
  .products-hero-image3 {
    width: 100%;
    height: 300px;
    margin-left: 0px;
    margin-bottom: 0px;
  }
  .products-content7 {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  .products-step1 {
    justify-content: center;
  }
  .products-text60 {
    color: var(--dl-color-scheme-white);
    line-height: 1.5;
  }
  .products-max-width8 {
    flex-direction: column;
  }
}
 
@media(max-width: 767px) {
  .products-hero {
    flex-direction: column;
  }
  .products-max-width1 {
    gap: 0;
    flex-direction: column;
  }
  .products-content4 {
    width: 100%;
    margin-top: var(--dl-layout-space-twounits);
    margin-right: 0px;
  }
  .products-image1 {
    width: 100%;
  }
  .products-hero-image1 {
    width: 100%;
    max-width: 100%;
    max-height: 300px;
    margin-right: 0px;
    padding-right: 0px;
    border-top-left-radius: 48px;
    border-top-right-radius: 48px;
  }
  .products-image2 {
    top: 66px;
    right: -137px;
    display: none;
  }
  .products-max-width3 {
    gap: var(--dl-layout-space-unit);
  }
  .products-text15 {
    font-family: Bricolage Grotesque;
  }
  .products-text17 {
    color: var(--dl-color-scheme-celadon);
  }
  .products-container11 {
    grid-template-columns: 1fr;
  }
  .products-container12 {
    width: 100%;
  }
  .products-container13 {
    width: 100%;
  }
  .products-container14 {
    width: 100%;
  }
  .products-link13 {
    font-style: normal;
    font-family: Bricolage Grotesque;
    font-weight: 700;
  }
  .products-content5 {
    width: 100%;
    margin-right: 0px;
  }
  .products-text28 {
    font-family: Bricolage Grotesque;
  }
  .products-text30 {
    color: var(--dl-color-scheme-celadon);
  }
  .products-text31 {
    color: var(--dl-color-scheme-celadon);
  }
  .products-max-width5 {
    flex-direction: column;
  }
  .products-container16 {
    width: 100%;
  }
  .products-container17 {
    width: 100%;
  }
  .products-container18 {
    width: 100%;
  }
  .products-container19 {
    width: 100%;
  }
  .products-content6 {
    width: 100%;
    margin-right: 0px;
  }
  .products-hero-image2 {
    height: var(--dl-layout-size-xxlarge);
  }
  .products-content7 {
    width: 100%;
    margin-right: 0px;
  }
  .products-banner {
    padding: var(--dl-layout-space-twounits);
  }
  .products-image8 {
    top: 40px;
    right: 1px;
  }
  .products-image9 {
    top: 2px;
    right: -166px;
  }
  .products-navlink4 {
    z-index: 100;
  }
}
 
@media(max-width: 479px) {
  .products-max-width3 {
    gap: var(--dl-layout-space-oneandhalfunits);
  }
  .products-container11 {
    display: grid;
    grid-template-columns: 1fr;
  }
  .products-text32 {
    color: var(--dl-color-scheme-green80);
    font-size: 24px;
  }
  .products-hero-image2 {
    height: var(--dl-layout-size-large);
  }
  .products-image7 {
    padding-bottom: 0px;
  }
  .products-hero-image3 {
    margin-bottom: var(--dl-layout-space-oneandhalfunits);
  }
  .products-text67 {
    font-family: Bricolage Grotesque;
  }
  .products-text69 {
    color: var(--dl-color-scheme-white);
  }
  .products-banner {
    padding-top: var(--dl-layout-space-twounits);
    padding-left: var(--dl-layout-space-twounits);
    padding-right: var(--dl-layout-space-twounits);
    padding-bottom: var(--dl-layout-space-twounits);
  }
  .products-text76 {
    line-height: 1.4;
  }
  .products-image8 {
    top: 143px;
    right: 1px;
  }
  .products-btns {
    gap: var(--dl-layout-space-unit);
    flex-direction: column;
  }
  .products-navlink3 {
    z-index: 100;
    text-align: center;
    font-family: Bricolage Grotesque;
  }
  .products-text77 {
    font-size: 16px;
    font-style: normal;
    font-family: Bricolage Grotesque;
    font-weight: 700;
  }
  .products-navlink4 {
    z-index: 100;
    font-size: 16px;
    font-family: Bricolage Grotesque;
  }
  .products-text78 {
    font-style: normal;
    font-family: Bricolage Grotesque;
    font-weight: 600;
  }
}
</style>
