<template>
  <div class="about-container">
    <app-header></app-header>
    <div class="about-hero hero-container section-container">
      <div class="about-max-width1 max-width">
        <div class="about-content10">
          <span class="about-subtitle">THE FUTURE OF PRIVATE CREDIT</span>
          <h1 class="about-title2">
            <span>
              About
              <span v-html="rawhxy2"></span>
            </span>
            <span class="about-text11">PlotPoint</span>
          </h1>
          <span class="about-description">
            <span>
              At PlotPoint, we are revolutionizing the property-backed lending
              market by seamlessly connecting loan originators with funders. Our
              platform leverages cutting-edge technology to offer fast, secure,
              and transparent funding solutions for real estate projects across
              England and Wales.
            </span>
            <br />
            <br />
            <span>
              With our deep expertise in property finance and a strong network of
              funders, we provide originators with the capital they need to
              execute their projects swiftly and efficiently. From bridging loans
              to light construction, we cater to a wide range of property-backed
              investments. Our tech-driven platform ensures that deals are
              submitted, reviewed, and approved with speed, providing funders with
              vetted, asset-backed opportunities and originators with the capital
              they require.
            </span>
          </span>
        </div>
        <div class="about-image1">
          <img
            alt="image"
            src="/aboutus/about-herotop-600w.jpg"
            class="about-hero-image"
          />
          <img
            alt="image"
            src="/common/union-400w.png"
            class="about-graphic-top"
          />
          <img
            alt="image"
            src="/common/group%2018-1200w.png"
            class="about-image2"
          />
        </div>
      </div>
    </div>
    <div class="about-layout302 thq-section-padding">
      <div class="about-max-width2 thq-section-max-width">
        <div class="about-section-title1 thq-flex-column">
          <span class="beforeHeading about-text16">OUR STRATEGY</span>
          <h1 class="about-text17">
            <span>
              Bridging the gap between
              <span v-html="rawy896"></span>
            </span>
            <span class="about-text19">
              capital
              <span v-html="rawm1kx"></span>
            </span>
            <span>and </span>
            <span class="about-text21">opportunity</span>
          </h1>
          <span class="about-text22">
            <span>
              At PlotPoint, our strategy is simple: connect high-quality loan
              originators with funders seeking asset-backed private credit
              opportunities. In today&apos;s market, securing capital swiftly is
              critical to the success of real estate projects, and our platform is
              designed to facilitate that process with maximum efficiency and
              transparency.
            </span>
            <br />
          </span>
        </div>
        <div class="about-content11">
          <div class="thq-grid-3">
            <div class="about-feature1 thq-flex-column">
              <div class="about-content12 thq-flex-column">
                <h3 class="thq-heading-3 about-title11">Building Partnerships</h3>
                <span class="about-description11 thq-body-small">
                  By focusing on bridging the gap between capital providers and
                  originators, we ensure that both parties benefit from our secure
                  and streamlined processes. Our rigorous vetting ensures that
                  only the most promising property-backed projects are presented
                  to funders, providing them with peace of mind and solid returns.
                  For originators, we offer the critical funding they need to
                  complete projects that are often overlooked by traditional
                  lenders.
                </span>
              </div>
            </div>
            <div class="about-feature2 thq-flex-column">
              <div class="about-content13 thq-flex-column">
                <h3 class="thq-heading-3 about-title12">
                  A Tech-Driven Solution
                </h3>
                <span class="about-description12 thq-body-small">
                  PlotPoint leverages technology to enhance the private credit
                  experience. Our platform simplifies everything from deal
                  submission to approval, speeding up a process that could
                  otherwise take weeks. Originators submit deals, undergo due
                  diligence, and get responses, usually within 24 hours. Funders,
                  on the other hand, have access to live project updates and
                  detailed portfolio information, ensuring complete transparency
                  throughout the loan term.
                </span>
              </div>
            </div>
            <div class="about-feature3 thq-flex-column">
              <div class="about-content14 thq-flex-column">
                <h3 class="thq-heading-3 about-title13">
                  Filling the Market Void
                </h3>
                <span class="about-description13 thq-body-small">
                  In the current financial landscape, many traditional lenders are
                  pulling back, leaving a gap in the market for private credit
                  opportunities. This market dislocation creates opportunities for
                  investors who want secure, asset-backed returns. PlotPoint steps
                  into this gap, providing funders with vetted investment options
                  while offering originators the capital they need to complete
                  property projects. As loans mature or new financing is required,
                  PlotPoint offers a platform for bridging the funding gap with
                  speed and efficiency.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="about-team8 thq-section-padding">
      <div class="about-max-width3 thq-section-max-width">
        <div class="about-section-title2 thq-flex-column">
          <span class="beforeHeading about-text25">OUR TEAM</span>
          <h1 class="about-text26">
            <span>
              Bringing
              <span v-html="raw6ugy"></span>
            </span>
            <span class="about-text28">
              experience
              <span v-html="rawqlv1"></span>
            </span>
            <span>
              across
              <span v-html="raw0bcc"></span>
            </span>
            <span class="about-text30">sectors</span>
          </h1>
        </div>
        <div class="about-content15">
          <div class="about-content16">
            <div class="about-row2">
              <div class="about-card1">
                <img
                  alt="Image of Piyush Shah, Co-Founder"
                  src="/aboutus/piyushshah-600w.png"
                  loading="lazy"
                  class="about-placeholder-image1 thq-img-ratio-1-1"
                />
                <div class="about-content17">
                  <div class="about-title3">
                    <span class="about-text31 thq-body-small">
                      <span>Piyush Shah</span>
                      <br />
                    </span>
                    <span class="about-text34 thq-body-small">
                      Co-Founder &amp; Chairman
                    </span>
                  </div>
                  <span class="about-text35 thq-body-small">
                    Piyush is a seasoned businessman with decades of experience in
                    both the UK and international markets. Having worked with and
                    established numerous large, complex organisations, he brings a
                    wealth of expertise to PlotPoint.
                  </span>
                </div>
              </div>
              <div class="about-card2">
                <img
                  alt="Image of Arya Taware, Co-Founder"
                  src="/aboutus/aryataware1-600w.jpg"
                  loading="lazy"
                  class="about-placeholder-image2 thq-img-ratio-1-1"
                />
                <div class="about-content18">
                  <div class="about-title4">
                    <span class="about-text36 thq-body-small">
                      <span>Arya Taware</span>
                      <br />
                    </span>
                    <span class="about-text39 thq-body-small">
                      Co-Founder &amp; Director
                    </span>
                  </div>
                  <span class="about-text40 thq-body-small">
                    Arya is a visionary leader with a passion for innovation in
                    the fintech and proptech industries. Featued in the Forbes 30
                    under 30, Arya brings years of experience in property finance
                    and technology, she drives the strategic direction of
                    PlotPoint.
                  </span>
                </div>
                <div class="about-social-icons1">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    class="thq-icon-small"
                  >
                    <path
                      d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"
                    ></path>
                  </svg>
                </div>
              </div>
              <div class="about-card3">
                <img
                  alt="Image of Udit Garg, Co-Founder"
                  src="/aboutus/udit-600w.jpg"
                  loading="lazy"
                  class="about-placeholder-image3 thq-img-ratio-1-1"
                />
                <div class="about-content19">
                  <div class="about-title5">
                    <span class="about-text41 thq-body-small">Udit Garg</span>
                    <span class="about-text42 thq-body-small">
                      Co-Founder &amp; Director
                    </span>
                  </div>
                  <span class="about-text43 thq-body-small">
                    With over 20 years of experience in diverse roles within
                    financial services, Udit has established himself as a
                    versatile and dependable professional. His approachable nature
                    and broad expertise make him a trusted advisor for clients
                    seeking comprehensive financial products
                  </span>
                </div>
                <div class="about-social-icons2">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    class="thq-icon-small"
                  >
                    <path
                      d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"
                    ></path>
                  </svg>
                </div>
              </div>
              <div class="about-card4">
                <img
                  alt="Image of Shadaan Tabani, Consultant"
                  src="/aboutus/shadaan-600w.jpg"
                  loading="lazy"
                  class="about-placeholder-image4 thq-img-ratio-1-1"
                />
                <div class="about-content20">
                  <div class="about-title6">
                    <span class="about-text44 thq-body-small">
                      Shadaan Tabani
                    </span>
                    <span class="about-text45 thq-body-small">Consultant</span>
                  </div>
                  <span class="about-text46 thq-body-small">
                    Shadaan is a detail-oriented professional with expertise in
                    operational and client management. With a background in
                    technology and finance, he works with the team to streamline
                    our operations, build relationships and manage our internal
                    and external systems.
                  </span>
                </div>
                <div class="about-social-icons3">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    class="thq-icon-small"
                  >
                    <path
                      d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-faq>
      <template v-slot:text2>
        <div class="about-fragment10">
          <span class="about-text47">lEARN MORE ABOUT PLOTPOINT </span>
        </div>
      </template>
      <template v-slot:text3>
        <div class="about-fragment11">
          <span class="about-text48">
            How does PlotPoint match originators with funders?
          </span>
        </div>
      </template>
      <template v-slot:text4>
        <div class="about-fragment12">
          <span class="about-text49">
            PlotPoint uses a proprietary algorithm and thorough due diligence
            process to connect loan originators with suitable funders. Our team
            and platform evaluates project details, risk appetite, and funding
            preferences to ensure the best possible match, delivering quick and
            efficient connections.
          </span>
        </div>
      </template>
      <template v-slot:text5>
        <div class="about-fragment13">
          <span class="about-text50">
            What types of loans do we offer on the platform?
          </span>
        </div>
      </template>
      <template v-slot:text6>
        <div class="about-fragment14">
          <span class="about-text51">
            PlotPoint focuses on property-backed loans, including bridging finance
            and development finance. These loans are secured against real assets,
            offering both originators and funders security and flexibility in
            their financial projects.
          </span>
        </div>
      </template>
      <template v-slot:text7>
        <div class="about-fragment15">
          <span class="about-text52">Is my investment secure?</span>
        </div>
      </template>
      <template v-slot:text8>
        <div class="about-fragment16">
          <span class="about-text53">
            While all investments on PlotPoint are secured by real, tangible
            assets and undergo rigorous due diligence—including property
            valuations and borrower assessments—your capital is at risk and not
            protected by the FSCS scheme. There is a possibility of losing some or
            all of your principal and interest.
          </span>
        </div>
      </template>
      <template v-slot:text9>
        <div class="about-fragment17">
          <span class="about-text54">Do you have an iOS or Android app?</span>
        </div>
      </template>
      <template v-slot:text10>
        <div class="about-fragment18">
          <span class="about-text55">
            Yes! PlotPoint offers a mobile app available on both iOS and Android,
            allowing you to monitor your investments or loans in real time,
            anytime, and anywhere.
          </span>
        </div>
      </template>
      <template v-slot:heading1>
        <div class="about-fragment19">
          <span class="about-text56">
            <span class="about-text57">
              Frequently Asked
              <span v-html="rawibr2"></span>
            </span>
            <br />
            <span class="about-text59">Questions</span>
          </span>
        </div>
      </template>
    </app-faq>
    <app-footer>
      <template v-slot:text>
        <div class="about-fragment20">
          <span class="about-text60">Learn More</span>
        </div>
      </template>
      <template v-slot:text1>
        <div class="about-fragment21">
          <span class="about-text61">About us</span>
        </div>
      </template>
      <template v-slot:text2>
        <div class="about-fragment22">
          <span class="about-text62">Private Credit</span>
        </div>
      </template>
      <template v-slot:text3>
        <div class="about-fragment23">
          <span class="about-text63">Partner with Us</span>
        </div>
      </template>
      <template v-slot:text5>
        <div class="about-fragment24">
          <span class="about-text64">Contact</span>
        </div>
      </template>
      <template v-slot:text6>
        <div class="about-fragment25"><span class="about-text65">App</span></div>
      </template>
      <template v-slot:text7>
        <div class="about-fragment26">
          <span class="about-text66">Download iOS app</span>
        </div>
      </template>
      <template v-slot:text8>
        <div class="about-fragment27">
          <span class="about-text67">Download Android app</span>
        </div>
      </template>
      <template v-slot:text9>
        <div class="about-fragment28">
          <span class="about-text68">Originator Portal</span>
        </div>
      </template>
      <template v-slot:link13>
        <div class="about-fragment29">
          <span class="about-text69">Your Capital is at Risk</span>
        </div>
      </template>
      <template v-slot:text10>
        <div class="about-fragment30">
          <span class="about-text70">Resources</span>
        </div>
      </template>
      <template v-slot:text11>
        <div class="about-fragment31">
          <span class="about-text71">Funder Terms and Conditions</span>
        </div>
      </template>
      <template v-slot:text13>
        <div class="about-fragment32">
          <span class="about-text72">Disclaimer</span>
        </div>
      </template>
      <template v-slot:text14>
        <div class="about-fragment33">
          <span class="about-text73">Privacy Policy</span>
        </div>
      </template>
      <template v-slot:text15>
        <div class="about-fragment34">
          <span class="about-text74">Products</span>
        </div>
      </template>
      <template v-slot:content1>
        <div class="about-fragment35">
          <span class="about-content1">
            <span>Fourth Floor, 70 Baker Street, </span>
            <span>London, W1U 7DL</span>
            <br />
          </span>
        </div>
      </template>
      <template v-slot:content2>
        <div class="about-fragment36">
          <span class="about-content2">
            <span class="about-text78">Phone: </span>
            <a href="tel:+442081547035" class="about-link1">+</a>
            <a href="tel:+442081547035" class="about-link2">44 208 154 7035</a>
            <br />
          </span>
        </div>
      </template>
      <template v-slot:content3>
        <div class="about-fragment37">
          <span class="about-content3">
            <span class="about-text80">
              ©2024 PlotPoint Limited. All rights reserved.
            </span>
            <br />
            <span>
              Registered in England &amp; Wales (Reg Number: 15914598).
              <span v-html="raw8d4k"></span>
            </span>
          </span>
        </div>
      </template>
      <template v-slot:contentTitle1>
        <div class="about-fragment38">
          <span class="about-text83">PlotPoint Limited</span>
        </div>
      </template>
    </app-footer>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import AppFaq from '../components/faq'
import AppFooter from '../components/footer'

export default {
  name: 'About',
  props: {},
  components: {
    AppHeader,
    AppFaq,
    AppFooter,
  },
  data() {
    return {
      rawhxy2: ' ',
      rawy896: ' ',
      rawm1kx: ' ',
      raw6ugy: ' ',
      rawqlv1: ' ',
      raw0bcc: ' ',
      rawibr2: ' ',
      raw8d4k: ' ',
    }
  },
  metaInfo: {
    title: 'About Us - PlotPoint',
    meta: [
      {
        name: 'description',
        content:
          'Learn about PlotPoint: expert property finance solutions with fast, hassle-free funding and dedicated support to empower your projects.',
      },
      {
        property: 'og:title',
        content: 'About Us - PlotPoint',
      },
      {
        property: 'og:description',
        content:
          'Learn about PlotPoint: expert property finance solutions with fast, hassle-free funding and dedicated support to empower your projects.',
      },
    ],
  },
}
</script>

<style scoped>
.about-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
 
.about-hero {
  padding-bottom: var(--dl-layout-space-twounits);
}
 
.about-max-width1 {
  align-items: center;
}
 
.about-content10 {
  flex: 0 0 auto;
  width: 45%;
  display: flex;
  margin-top: var(--dl-layout-space-threeunits);
  align-items: flex-start;
  margin-right: var(--dl-layout-space-twounits);
  margin-bottom: var(--dl-layout-space-threeunits);
  flex-direction: column;
}
 
.about-subtitle {
  color: var(--dl-color-scheme-white);
  font-size: 14px;
  font-style: normal;
  font-family: "Urbanist";
  font-weight: 800;
  line-height: 1.15;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  text-decoration: none;
}
 
.about-title2 {
  color: var(--dl-color-scheme-white);
  font-size: 52px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: var(--dl-layout-space-unit);
}
 
.about-text11 {
  color: var(--dl-color-scheme-celadon);
}
 
.about-description {
  color: var(--dl-color-scheme-white);
  font-size: 18px;
  font-family: "Bricolage Grotesque";
  line-height: 1.5;
  padding-bottom: var(--dl-layout-space-twounits);
}
 
.about-image1 {
  position: relative;
  padding-bottom: var(--dl-layout-space-fourunits);
}
 
.about-hero-image {
  flex: 1;
  max-width: 550px;
  object-fit: cover;
  border-radius: 48px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
 
.about-graphic-top {
  top: 0px;
  right: -170px;
  width: 100%;
  height: 100%;
  position: absolute;
  max-width: 359px;
  max-height: 359px;
  object-fit: contain;
  object-position: center;
}
 
.about-image2 {
  right: -170px;
  bottom: 0px;
  position: absolute;
  object-fit: contain;
}
 
.about-layout302 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.about-max-width2 {
  gap: var(--dl-layout-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.about-section-title1 {
  gap: 0;
  align-items: center;
}
 
.about-text17 {
  font-size: 42px;
  font-style: normal;
  text-align: center;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-layout-space-unit);
}
 
.about-text19 {
  color: var(--dl-color-scheme-celadon);
}
 
.about-text21 {
  color: var(--dl-color-scheme-celadon);
}
 
.about-text22 {
  font-size: 16px;
  text-align: center;
  line-height: 1.5;
  margin-bottom: 0px;
}
 
.about-content11 {
  gap: var(--dl-layout-space-threeunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.about-feature1 {
  align-items: flex-start;
}
 
.about-content12 {
  gap: 0;
}
 
.about-description11 {
  text-align: center;
  font-family: "Bricolage Grotesque";
}
 
.about-feature2 {
  align-items: flex-start;
}
 
.about-content13 {
  gap: 0;
}
 
.about-description12 {
  text-align: center;
  font-family: "Bricolage Grotesque";
}
 
.about-feature3 {
  align-items: flex-start;
}
 
.about-content14 {
  gap: 0;
}
 
.about-description13 {
  text-align: center;
  font-family: "Bricolage Grotesque";
}
 
.about-team8 {
  background-color: var(--dl-color-scheme-celadon);
}
 
.about-max-width3 {
  gap: var(--dl-layout-space-unit);
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
 
.about-section-title2 {
  gap: 0;
  width: 100%;
  align-items: center;
}
 
.about-text26 {
  font-size: 42px;
  font-style: normal;
  text-align: center;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-layout-space-unit);
}
 
.about-text28 {
  color: var(--dl-color-scheme-white);
}
 
.about-text30 {
  color: var(--dl-color-scheme-white);
}
 
.about-content15 {
  gap: 96px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.about-content16 {
  gap: var(--dl-layout-space-fourunits);
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.about-row2 {
  gap: 48px;
  display: grid;
  max-width: 1200px;
  align-self: center;
  flex-shrink: 0;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
 
.about-card1 {
  gap: var(--dl-layout-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 600px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.about-placeholder-image1 {
  width: var(--dl-layout-size-xlarge);
}
 
.about-content17 {
  gap: var(--dl-layout-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.about-title3 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.about-text31 {
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 600;
}
 
.about-text34 {
  font-family: "Bricolage Grotesque";
}
 
.about-text35 {
  text-align: center;
  font-family: "Bricolage Grotesque";
}
 
.about-card2 {
  gap: var(--dl-layout-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 600px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.about-placeholder-image2 {
  width: var(--dl-layout-size-xlarge);
}
 
.about-content18 {
  gap: var(--dl-layout-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.about-title4 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.about-text36 {
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 600;
}
 
.about-text39 {
  font-family: "Bricolage Grotesque";
}
 
.about-text40 {
  text-align: center;
  font-family: "Bricolage Grotesque";
}
 
.about-social-icons1 {
  gap: var(--dl-layout-space-unit);
  display: flex;
  align-items: flex-start;
}
 
.about-card3 {
  gap: var(--dl-layout-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 600px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.about-placeholder-image3 {
  width: var(--dl-layout-size-xlarge);
}
 
.about-content19 {
  gap: var(--dl-layout-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.about-title5 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.about-text41 {
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 600;
}
 
.about-text42 {
  font-family: "Bricolage Grotesque";
}
 
.about-text43 {
  text-align: center;
  font-family: "Bricolage Grotesque";
}
 
.about-social-icons2 {
  gap: var(--dl-layout-space-unit);
  display: flex;
  align-items: flex-start;
}
 
.about-card4 {
  gap: var(--dl-layout-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  max-width: 600px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.about-placeholder-image4 {
  width: var(--dl-layout-size-xlarge);
}
 
.about-content20 {
  gap: var(--dl-layout-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.about-title6 {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
 
.about-text44 {
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 600;
}
 
.about-text45 {
  font-family: "Bricolage Grotesque";
}
 
.about-text46 {
  text-align: center;
  font-family: "Bricolage Grotesque";
}
 
.about-social-icons3 {
  gap: var(--dl-layout-space-unit);
  display: flex;
  align-items: flex-start;
}
 
.about-fragment10 {
  display: contents;
}
 
.about-text47 {
  display: inline-block;
}
 
.about-fragment11 {
  display: contents;
}
 
.about-text48 {
  display: inline-block;
}
 
.about-fragment12 {
  display: contents;
}
 
.about-text49 {
  display: inline-block;
}
 
.about-fragment13 {
  display: contents;
}
 
.about-text50 {
  display: inline-block;
}
 
.about-fragment14 {
  display: contents;
}
 
.about-text51 {
  display: inline-block;
}
 
.about-fragment15 {
  display: contents;
}
 
.about-text52 {
  display: inline-block;
}
 
.about-fragment16 {
  display: contents;
}
 
.about-text53 {
  display: inline-block;
}
 
.about-fragment17 {
  display: contents;
}
 
.about-text54 {
  display: inline-block;
}
 
.about-fragment18 {
  display: contents;
}
 
.about-text55 {
  display: inline-block;
}
 
.about-fragment19 {
  display: contents;
}
 
.about-text56 {
  display: inline-block;
}
 
.about-text57 {
  font-family: "Urbanist";
}
 
.about-text59 {
  color: var(--dl-color-scheme-celadon);
  font-family: "Urbanist";
}
 
.about-fragment20 {
  display: contents;
}
 
.about-text60 {
  display: inline-block;
}
 
.about-fragment21 {
  display: contents;
}
 
.about-text61 {
  display: inline-block;
}
 
.about-fragment22 {
  display: contents;
}
 
.about-text62 {
  display: inline-block;
}
 
.about-fragment23 {
  display: contents;
}
 
.about-text63 {
  display: inline-block;
}
 
.about-fragment24 {
  display: contents;
}
 
.about-text64 {
  display: inline-block;
}
 
.about-fragment25 {
  display: contents;
}
 
.about-text65 {
  display: inline-block;
}
 
.about-fragment26 {
  display: contents;
}
 
.about-text66 {
  display: inline-block;
}
 
.about-fragment27 {
  display: contents;
}
 
.about-text67 {
  display: inline-block;
}
 
.about-fragment28 {
  display: contents;
}
 
.about-text68 {
  display: inline-block;
}
 
.about-fragment29 {
  display: contents;
}
 
.about-text69 {
  display: inline-block;
}
 
.about-fragment30 {
  display: contents;
}
 
.about-text70 {
  display: inline-block;
}
 
.about-fragment31 {
  display: contents;
}
 
.about-text71 {
  display: inline-block;
}
 
.about-fragment32 {
  display: contents;
}
 
.about-text72 {
  display: inline-block;
}
 
.about-fragment33 {
  display: contents;
}
 
.about-text73 {
  display: inline-block;
}
 
.about-fragment34 {
  display: contents;
}
 
.about-text74 {
  display: inline-block;
}
 
.about-fragment35 {
  display: contents;
}
 
.about-content1 {
  display: inline-block;
}
 
.about-fragment36 {
  display: contents;
}
 
.about-content2 {
  display: inline-block;
}
 
.about-text78 {
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
}
 
.about-link1 {
  text-decoration: underline;
}
 
.about-link2 {
  text-decoration: underline;
}
 
.about-fragment37 {
  display: contents;
}
 
.about-content3 {
  display: inline-block;
}
 
.about-text80 {
  font-weight: 700;
}
 
.about-fragment38 {
  display: contents;
}
 
.about-text83 {
  display: inline-block;
}
 
@media(max-width: 1200px) {
  .about-text11 {
    color: var(--dl-color-scheme-celadon);
  }
  .about-text21 {
    color: var(--dl-color-scheme-celadon);
  }
}
 
@media(max-width: 991px) {
  .about-content10 {
    width: 50%;
    margin-right: var(--dl-layout-space-unit);
  }
  .about-image1 {
    padding-bottom: var(--dl-layout-space-threeunits);
  }
  .about-hero-image {
    margin-bottom: 0px;
  }
  .about-text16 {
    text-align: center;
  }
  .about-text17 {
    text-align: center;
  }
  .about-text22 {
    text-align: center;
  }
  .about-title11 {
    text-align: center;
  }
  .about-title12 {
    text-align: center;
  }
  .about-title13 {
    text-align: center;
  }
  .about-text25 {
    text-align: center;
  }
  .about-text26 {
    text-align: center;
  }
  .about-row2 {
    align-items: center;
    place-items: start center;
    flex-direction: row;
    grid-template-columns: 1fr 1fr;
  }
}
 
@media(max-width: 767px) {
  .about-max-width1 {
    flex-direction: column;
  }
  .about-content10 {
    width: 100%;
    margin-right: 0px;
  }
  .about-hero-image {
    max-height: 500px;
    margin-right: 0px;
    border-top-left-radius: 48px;
    border-top-right-radius: 48px;
  }
  .about-graphic-top {
    display: none;
  }
  .about-image2 {
    top: 2px;
    right: -166px;
  }
  .about-layout302 {
    padding-left: var(--dl-layout-space-oneandhalfunits);
    padding-right: var(--dl-layout-space-oneandhalfunits);
  }
  .about-section-title1 {
    align-items: flex-start;
  }
  .about-text17 {
    text-align: left;
    font-family: Bricolage Grotesque;
  }
  .about-text19 {
    color: var(--dl-color-scheme-medgreen);
  }
  .about-text21 {
    color: var(--dl-color-scheme-medgreen);
  }
  .about-text22 {
    text-align: left;
  }
  .about-content12 {
    align-items: flex-start;
  }
  .about-title11 {
    text-align: left;
  }
  .about-description11 {
    text-align: left;
  }
  .about-content13 {
    align-items: flex-start;
  }
  .about-description12 {
    text-align: left;
  }
  .about-content14 {
    align-items: flex-start;
  }
  .about-description13 {
    text-align: left;
  }
  .about-text26 {
    text-align: center;
    font-family: Bricolage Grotesque;
  }
  .about-row2 {
    flex-direction: column;
  }
  .about-placeholder-image1 {
    width: var(--dl-layout-size-xlarge);
  }
  .about-placeholder-image2 {
    width: var(--dl-layout-size-xlarge);
  }
  .about-placeholder-image3 {
    width: var(--dl-layout-size-xlarge);
  }
  .about-placeholder-image4 {
    width: var(--dl-layout-size-xlarge);
  }
}
 
@media(max-width: 479px) {
  .about-image1 {
    padding-bottom: 0px;
  }
  .about-max-width2 {
    gap: var(--dl-layout-space-oneandhalfunits);
  }
  .about-text26 {
    text-align: center;
    font-family: Bricolage Grotesque;
    line-height: 1.1;
  }
  .about-text28 {
    color: var(--dl-color-scheme-white);
  }
  .about-text30 {
    color: var(--dl-color-scheme-white);
  }
  .about-row2 {
    grid-template-columns: 1fr;
  }
  .about-placeholder-image1 {
    height: var(--dl-layout-size-xlarge);
  }
  .about-placeholder-image2 {
    height: var(--dl-layout-size-xlarge);
  }
  .about-placeholder-image3 {
    height: var(--dl-layout-size-xlarge);
  }
  .about-placeholder-image4 {
    height: var(--dl-layout-size-xlarge);
  }
}
</style>
