<template>
  <div class="book-a-call-container1">
    <app-header></app-header>
    <div class="book-a-call-container2 thq-section-padding">
      <div class="book-a-call-max-width thq-section-max-width">
        <div class="book-a-call-contact1 thq-section-padding">
          <div class="book-a-call-section-title1 thq-flex-column">
            <span class="book-a-call-text10 beforeHeading">
              START YOUR JOURNEY WITH PLOTPOINT TODAY
            </span>
            <div class="book-a-call-content">
              <h2 class="book-a-call-text11 thq-heading-2">
                <span>
                  Book a
                  <span v-html="rawvnoj"></span>
                </span>
                <span class="book-a-call-text13">Call</span>
              </h2>
              <span class="book-a-call-text14 thq-body-small">
                Book a 30 minute call with PlotPoint&apos;s dedicated customer
                success team to discuss how we can help you achieve your finance
                goals.
              </span>
            </div>
          </div>
          <div class="book-a-call-section-title2 thq-flex-column">
            <iframe
              src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ3pVVRAgHheTRT9QwUD8CA2n7qLXtYEu2hxEHAX15TFZehD-iXzyrwWvDpp8Wxbl3T5VapI6v8F?gv=true"
              class="book-a-call-iframe"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <app-footer>
      <template v-slot:text>
        <div class="book-a-call-fragment10">
          <span class="book-a-call-text15">Learn More</span>
        </div>
      </template>
      <template v-slot:text1>
        <div class="book-a-call-fragment11">
          <span class="book-a-call-text16">About us</span>
        </div>
      </template>
      <template v-slot:text2>
        <div class="book-a-call-fragment12">
          <span class="book-a-call-text17">Private Credit</span>
        </div>
      </template>
      <template v-slot:text3>
        <div class="book-a-call-fragment13">
          <span class="book-a-call-text18">Partner with Us</span>
        </div>
      </template>
      <template v-slot:text5>
        <div class="book-a-call-fragment14">
          <span class="book-a-call-text19">Contact</span>
        </div>
      </template>
      <template v-slot:text6>
        <div class="book-a-call-fragment15">
          <span class="book-a-call-text20">App</span>
        </div>
      </template>
      <template v-slot:text7>
        <div class="book-a-call-fragment16">
          <span class="book-a-call-text21">Download iOS app</span>
        </div>
      </template>
      <template v-slot:text8>
        <div class="book-a-call-fragment17">
          <span class="book-a-call-text22">Download Android app</span>
        </div>
      </template>
      <template v-slot:text9>
        <div class="book-a-call-fragment18">
          <span class="book-a-call-text23">Originator Portal</span>
        </div>
      </template>
      <template v-slot:link13>
        <div class="book-a-call-fragment19">
          <span class="book-a-call-text24">Your Capital is at Risk</span>
        </div>
      </template>
      <template v-slot:text10>
        <div class="book-a-call-fragment20">
          <span class="book-a-call-text25">Resources</span>
        </div>
      </template>
      <template v-slot:text11>
        <div class="book-a-call-fragment21">
          <span class="book-a-call-text26">Funder Terms and Conditions</span>
        </div>
      </template>
      <template v-slot:text13>
        <div class="book-a-call-fragment22">
          <span class="book-a-call-text27">Disclaimer</span>
        </div>
      </template>
      <template v-slot:text14>
        <div class="book-a-call-fragment23">
          <span class="book-a-call-text28">Privacy Policy</span>
        </div>
      </template>
      <template v-slot:text15>
        <div class="book-a-call-fragment24">
          <span class="book-a-call-text29">Products</span>
        </div>
      </template>
      <template v-slot:content1>
        <div class="book-a-call-fragment25">
          <span class="book-a-call-content1">
            <span>Fourth Floor, 70 Baker Street, </span>
            <span>London, W1U 7DL</span>
            <br />
          </span>
        </div>
      </template>
      <template v-slot:content2>
        <div class="book-a-call-fragment26">
          <span class="book-a-call-content2">
            <span class="book-a-call-text33">Phone: </span>
            <a href="tel:+442081547035" class="book-a-call-link1">+</a>
            <a href="tel:+442081547035" class="book-a-call-link2">
              44 208 154 7035
            </a>
            <br />
          </span>
        </div>
      </template>
      <template v-slot:content3>
        <div class="book-a-call-fragment27">
          <span class="book-a-call-content3">
            <span class="book-a-call-text35">
              ©2024 PlotPoint Limited. All rights reserved.
            </span>
            <br />
            <span>
              Registered in England &amp; Wales (Reg Number: 15914598).
              <span v-html="rawreaw"></span>
            </span>
          </span>
        </div>
      </template>
      <template v-slot:contentTitle1>
        <div class="book-a-call-fragment28">
          <span class="book-a-call-text38">PlotPoint Limited</span>
        </div>
      </template>
    </app-footer>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import AppFooter from '../components/footer'

export default {
  name: 'BookACall',
  props: {},
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      rawvnoj: ' ',
      rawreaw: ' ',
    }
  },
  metaInfo: {
    title: 'Book a Call - PlotPoint',
    meta: [
      {
        property: 'og:title',
        content: 'Book a Call - PlotPoint',
      },
    ],
  },
}
</script>

<style scoped>
.book-a-call-container1 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
 
.book-a-call-container2 {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-scheme-green100);
}
 
.book-a-call-max-width {
  align-self: center;
}
 
.book-a-call-contact1 {
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: 0px;
  flex-direction: row;
  padding-bottom: 0px;
  justify-content: flex-start;
}
 
.book-a-call-section-title1 {
  gap: 0;
  width: 50%;
  align-items: flex-start;
  padding-right: var(--dl-layout-space-unit);
  justify-content: flex-start;
}
 
.book-a-call-text10 {
  color: var(--dl-color-scheme-white);
}
 
.book-a-call-content {
  gap: var(--dl-layout-space-unit);
  display: flex;
  align-self: stretch;
  margin-bottom: var(--dl-layout-space-unit);
  flex-direction: column;
}
 
.book-a-call-text11 {
  fill: var(--dl-color-scheme-white);
  color: var(--dl-color-scheme-white);
}
 
.book-a-call-text14 {
  fill: var(--dl-color-scheme-white);
  color: var(--dl-color-scheme-white);
  font-family: "Bricolage Grotesque";
}
 
.book-a-call-section-title2 {
  gap: 0;
  width: 50%;
  align-items: center;
  padding-right: var(--dl-layout-space-unit);
  justify-content: flex-start;
  background-color: var(--dl-color-scheme-celadon);
}
 
.book-a-call-iframe {
  width: 392px;
  height: 1500px;
  max-width: 100%;
  margin-left: var(--dl-layout-space-unit);
  margin-right: var(--dl-layout-space-unit);
  margin-bottom: var(--dl-layout-space-unit);
}
 
.book-a-call-fragment10 {
  display: contents;
}
 
.book-a-call-text15 {
  display: inline-block;
}
 
.book-a-call-fragment11 {
  display: contents;
}
 
.book-a-call-text16 {
  display: inline-block;
}
 
.book-a-call-fragment12 {
  display: contents;
}
 
.book-a-call-text17 {
  display: inline-block;
}
 
.book-a-call-fragment13 {
  display: contents;
}
 
.book-a-call-text18 {
  display: inline-block;
}
 
.book-a-call-fragment14 {
  display: contents;
}
 
.book-a-call-text19 {
  display: inline-block;
}
 
.book-a-call-fragment15 {
  display: contents;
}
 
.book-a-call-text20 {
  display: inline-block;
}
 
.book-a-call-fragment16 {
  display: contents;
}
 
.book-a-call-text21 {
  display: inline-block;
}
 
.book-a-call-fragment17 {
  display: contents;
}
 
.book-a-call-text22 {
  display: inline-block;
}
 
.book-a-call-fragment18 {
  display: contents;
}
 
.book-a-call-text23 {
  display: inline-block;
}
 
.book-a-call-fragment19 {
  display: contents;
}
 
.book-a-call-text24 {
  display: inline-block;
}
 
.book-a-call-fragment20 {
  display: contents;
}
 
.book-a-call-text25 {
  display: inline-block;
}
 
.book-a-call-fragment21 {
  display: contents;
}
 
.book-a-call-text26 {
  display: inline-block;
}
 
.book-a-call-fragment22 {
  display: contents;
}
 
.book-a-call-text27 {
  display: inline-block;
}
 
.book-a-call-fragment23 {
  display: contents;
}
 
.book-a-call-text28 {
  display: inline-block;
}
 
.book-a-call-fragment24 {
  display: contents;
}
 
.book-a-call-text29 {
  display: inline-block;
}
 
.book-a-call-fragment25 {
  display: contents;
}
 
.book-a-call-content1 {
  display: inline-block;
}
 
.book-a-call-fragment26 {
  display: contents;
}
 
.book-a-call-content2 {
  display: inline-block;
}
 
.book-a-call-text33 {
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
}
 
.book-a-call-link1 {
  text-decoration: underline;
}
 
.book-a-call-link2 {
  text-decoration: underline;
}
 
.book-a-call-fragment27 {
  display: contents;
}
 
.book-a-call-content3 {
  display: inline-block;
}
 
.book-a-call-text35 {
  font-weight: 700;
}
 
.book-a-call-fragment28 {
  display: contents;
}
 
.book-a-call-text38 {
  display: inline-block;
}
 
@media(max-width: 1200px) {
  .book-a-call-text13 {
    color: var(--dl-color-scheme-celadon);
  }
}
 
@media(max-width: 991px) {
  .book-a-call-contact1 {
    flex-direction: column;
  }
  .book-a-call-section-title1 {
    width: 100%;
    padding-bottom: var(--dl-layout-space-twounits);
  }
  .book-a-call-section-title2 {
    width: 100%;
    align-self: center;
    align-items: center;
    padding-bottom: var(--dl-layout-space-twounits);
  }
  .book-a-call-iframe {
    width: 478px;
  }
}
 
@media(max-width: 767px) {
  .book-a-call-contact1 {
    padding-left: var(--dl-layout-space-unit);
    padding-right: var(--dl-layout-space-unit);
    flex-direction: column;
  }
  .book-a-call-text11 {
    color: var(--dl-color-scheme-white);
  }
  .book-a-call-text14 {
    color: var(--dl-color-scheme-white);
    font-family: Bricolage Grotesque;
  }
  .book-a-call-section-title2 {
    align-self: center;
    align-items: center;
  }
  .book-a-call-iframe {
    width: 482px;
  }
}
 
@media(max-width: 479px) {
  .book-a-call-container2 {
    margin-bottom: var(--dl-layout-space-twounits);
  }
  .book-a-call-text14 {
    text-align: left;
  }
  .book-a-call-section-title2 {
    align-self: center;
  }
  .book-a-call-iframe {
    width: 315px;
    align-self: center;
    margin-left: 0px;
    margin-right: 0px;
  }
}
</style>
