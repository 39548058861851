<template>
  <div class="privacy-policy-container">
    <app-header></app-header>
    <div class="privacy-policy-hero hero-container section-container">
      <div class="privacy-policy-max-width1 max-width">
        <div class="privacy-policy-content10">
          <span class="privacy-policy-subtitle">HOW WE TREAT YOUR DATA</span>
          <h1 class="privacy-policy-title">
            <span>
              Privacy
              <span v-html="rawhdrc"></span>
            </span>
            <span class="privacy-policy-text101">Policy</span>
            <br />
          </h1>
        </div>
        <div class="privacy-policy-image">
          <img
            alt="image"
            src="/common/union-400w.png"
            class="privacy-policy-graphic-top"
          />
        </div>
      </div>
    </div>
    <div class="privacy-policy-layout302 thq-section-padding">
      <div class="privacy-policy-max-width2 thq-section-max-width">
        <div class="privacy-policy-content11 thq-flex-column">
          <h3 class="privacy-policy-text103">
            <span>How we use your </span>
            <span class="privacy-policy-text105">data</span>
          </h3>
          <span class="privacy-policy-text106">
            <span>
              This privacy notice explains how PlotPoint Limited (t/a PlotPoint)
              collects, uses, and stores any personal information about you.
            </span>
            <br />
          </span>
        </div>
        <div class="privacy-policy-content12 thq-flex-column">
          <h4 class="privacy-policy-text109">
            What Information Do We Collect About You and Why?
          </h4>
          <span class="privacy-policy-text110">
            <span>
              PlotPoint collects and uses personal data on the basis that it is in
              its legitimate interests to do so and your right to privacy is not
              overridden. We need to do so as part of the performance of our
              contract with you or where we have a legal obligation to do so.
            </span>
            <br />
          </span>
          <ul class="privacy-policy-list1 list">
            <li class="privacy-policy-li10 list-item">
              <span class="privacy-policy-text113">
                <span class="privacy-policy-text114">
                  From Our Clients and Originators
                </span>
                <br />
                <span>
                  We collect information through the PlotPoint app, website, and
                  during our interactions with you throughout our relationship to
                  comply with regulations and perform our contract with you. The
                  provision of your personal data is required in order to enter
                  into a contract with us; however, the provision of information
                  for marketing purposes is voluntary. We may record and monitor
                  telephone calls made to or by us in order to comply with
                  regulatory obligations and training purposes. Automated
                  decision-making may be used in order to comply with our KYC/AML
                  checks or to evidence that PlotPoint is providing a suitable and
                  appropriate service to its clients.
                </span>
              </span>
            </li>
            <li class="privacy-policy-li11 list-item">
              <span class="privacy-policy-text117">
                <span class="privacy-policy-text118">From Our Website</span>
                <span><span v-html="rawyhp4"></span></span>
                <br />
                <span>
                  When you visit our website, we may collect information about
                  your visit such as your IP address and the pages you visited,
                  and when you use our service, we may collect information on how
                  you use our service.
                </span>
              </span>
            </li>
          </ul>
        </div>
        <div class="privacy-policy-content13 thq-flex-column">
          <h4 class="privacy-policy-text122">
            How Do We Process Your Information?
          </h4>
          <span class="privacy-policy-text123">
            <span>
              All information is processed both manually and electronically in
              compliance with current data protection regulations. We allow only
              authorised PlotPoint employees and carefully selected Third-Party
              Affiliates to have access to your information. Such employees and
              Third-Party Affiliates are appropriately designated and trained to
              process data only according to the instructions we provide them.
              Please note that our offices will have CCTV and record your image
              upon any in-person meeting at our London office.
            </span>
            <br />
          </span>
        </div>
        <div class="privacy-policy-content14 thq-flex-column">
          <h4 class="privacy-policy-text126">
            How Do We Store Your Information?
          </h4>
          <span class="privacy-policy-text127">
            <span>
              PlotPoint will retain personal data for a reasonable period,
              considering legitimate business needs to capture and retain such
              information. Information will also be retained for a period
              necessary to comply with local regulations, or country-specific
              regulations and requirements, and in accordance with PlotPoint’s
              Document Retention Schedule.
            </span>
            <br />
          </span>
        </div>
        <div class="privacy-policy-content15 thq-flex-column">
          <h4 class="privacy-policy-text130">
            How Long Do We Keep This Information?
          </h4>
          <ul class="privacy-policy-list2 list">
            <li class="privacy-policy-li12 list-item">
              <span class="privacy-policy-text131">
                <span class="privacy-policy-text132">
                  Non-clients:
                  <span v-html="raw0qhk"></span>
                </span>
                <span>
                  If you are not a client of PlotPoint, we will retain your
                  information for one year after our last interaction with you. If
                  you ask us to remove you from our marketing list, we may keep
                  your name and communication details on an “opt-out” list in
                  order to ensure that we do not market to you again.
                </span>
              </span>
            </li>
            <li class="privacy-policy-li13 list-item">
              <span class="privacy-policy-text134">
                <span class="privacy-policy-text135">Clients:</span>
                <span>
                  If you are or were a PlotPoint client, we keep most of your
                  information for five (5) years after the closure of your account
                  and the redemption of any funding arrangements held under it.
                  Where required by law or regulation, we may keep documents
                  longer than the designated five (5) years.
                </span>
              </span>
            </li>
          </ul>
        </div>
        <div class="privacy-policy-content16 thq-flex-column">
          <h4 class="privacy-policy-text137">
            Who Do We Share Your Information With?
          </h4>
          <ul class="privacy-policy-list3 list">
            <li class="privacy-policy-li14 list-item">
              <span class="privacy-policy-text138">
                <span>
                  Our IT providers, and all other service providers and
                  Third-Party Affiliates in order to provide and maintain the
                  provision of our services.
                </span>
                <br class="privacy-policy-text140" />
              </span>
            </li>
            <li class="privacy-policy-li15 list-item">
              <span class="privacy-policy-text141">
                Loan Originators involved in the specific transactions for which
                you have chosen to become a Funder, in order to facilitate due
                diligence, loan administration, and deal execution.
              </span>
            </li>
            <li class="privacy-policy-li16 list-item">
              <span class="privacy-policy-text142">
                Companies affiliated with our company for operational or
                administrative purposes
              </span>
            </li>
            <li class="privacy-policy-li17 list-item">
              <span class="privacy-policy-text143">
                With your explicit consent, we may also share your information
                with companies within our group for cross-marketing purposes.
              </span>
            </li>
            <li class="privacy-policy-li18 list-item">
              <span class="privacy-policy-text144">
                Our appointed auditors, accountants, lawyers, and other
                professional advisers, to the extent that they require access to
                the information to provide advice.
              </span>
            </li>
            <li class="privacy-policy-li19 list-item">
              <span class="privacy-policy-text145">
                Fraud prevention agencies and other organisations to allow us to
                undertake relevant checks.
              </span>
            </li>
            <li class="privacy-policy-li20 list-item">
              <span class="privacy-policy-text146">
                If required to do so to meet applicable law, the order of a court
                of rules and codes of practice applicable to the circumstances at
                the time.
              </span>
            </li>
            <li class="privacy-policy-li21 list-item">
              <span class="privacy-policy-text147">
                Relevant authorities to investigate or prevent fraud or activities
                believed to be illegal or otherwise in breach of applicable law.
              </span>
            </li>
            <li class="privacy-policy-li22 list-item">
              <span class="privacy-policy-text148">
                If tax regulations require us to collect information about tax
                residency, then in certain circumstances (including if we do not
                receive a valid self-certification from you), we may be obliged to
                share information about your passion product and collectible
                profile with the relevant tax, payments, and customs authority,
                who may pass this onto tax authorities in other jurisdictions.
              </span>
            </li>
            <li class="privacy-policy-li23 list-item">
              <span class="privacy-policy-text149">
                In the event that we sell or buy any business or assets, in which
                case we will disclose your personal information to the prospective
                seller or buyer of such business or assets.
              </span>
            </li>
            <li class="privacy-policy-li24 list-item">
              <span class="privacy-policy-text150">
                If assets, including personal information held by our Third-Party
                Affiliates, are acquired by a third party, personal information
                may be transferred as part of the acquisition.
              </span>
            </li>
          </ul>
          <span class="privacy-policy-text151">
            <span>
              We will not lend or sell your information to undisclosed Third-Party
              Affiliates. Your personal information may need to be shared with our
              service providers, which may involve transferring it to countries
              outside the European Economic Area (EEA). Where we do so, we will
              ensure that we do this in accordance with current data protection
              legislation by only transferring your data to jurisdictions in
              respect of which there is a European Commission adequacy decision
              or, where this is not the case, by using model clauses which have
              been approved by the European Commission.
            </span>
            <br />
          </span>
        </div>
        <div class="privacy-policy-content17 thq-flex-column">
          <h4 class="privacy-policy-text154">Lawful Basis for Processing</h4>
          <span class="privacy-policy-text155">
            <span>
              We process your personal data based on the following lawful bases:
            </span>
            <br />
          </span>
          <ul class="privacy-policy-list4 list">
            <li class="privacy-policy-li25 list-item">
              <span class="privacy-policy-text158">
                <span class="privacy-policy-text159">Consent</span>
                <span>
                  : Where you have given explicit consent for processing your data
                  for specific purposes.
                </span>
              </span>
            </li>
            <li class="privacy-policy-li26 list-item">
              <span class="privacy-policy-text161">
                <span class="privacy-policy-text162">Contract:</span>
                <span>
                  Where processing is necessary for the performance of a contract
                  with you.
                </span>
              </span>
            </li>
            <li class="privacy-policy-li27 list-item">
              <span class="privacy-policy-text164">
                <span class="privacy-policy-text165">
                  Legal Obligation:
                  <span v-html="raw1dnx"></span>
                </span>
                <span>
                  Where processing is necessary to comply with a legal obligation.
                </span>
              </span>
            </li>
            <li class="privacy-policy-li28 list-item">
              <span class="privacy-policy-text167">
                <span class="privacy-policy-text168">
                  Legitimate Interests:
                  <span v-html="raw58cv"></span>
                </span>
                <span>
                  Where processing is necessary for the purposes of legitimate
                  interests pursued by us or a third party, provided that such
                  interests are not overridden by your interests or fundamental
                  rights and freedoms.
                </span>
              </span>
            </li>
          </ul>
        </div>
        <div class="privacy-policy-content18 thq-flex-column">
          <h4 class="privacy-policy-text170">Your Rights</h4>
          <span class="privacy-policy-text171">
            <span>
              You have certain rights in respect of the data we hold. These
              include:
            </span>
            <br />
          </span>
          <ul class="privacy-policy-list5 list">
            <li class="privacy-policy-li29 list-item">
              <span class="privacy-policy-text174">
                <span class="privacy-policy-text175">Right to Access:</span>
                <span>
                  You have the right to request a copy of the information we hold
                  about you.
                </span>
              </span>
            </li>
            <li class="privacy-policy-li30 list-item">
              <span class="privacy-policy-text177">
                <span class="privacy-policy-text178">
                  Right to Rectification:
                </span>
                <span>
                  You have the right to request correction of inaccurate or
                  incomplete information.
                </span>
                <br />
              </span>
            </li>
            <li class="privacy-policy-li31 list-item">
              <span class="privacy-policy-text181">
                <span class="privacy-policy-text182">
                  Right to Erasure:
                  <span v-html="rawu7cx"></span>
                </span>
                <span>
                  You have the right to request the deletion of your personal
                  data.
                </span>
              </span>
            </li>
            <li class="privacy-policy-li32 list-item">
              <span class="privacy-policy-text184">
                <span class="privacy-policy-text185">Right to Access:</span>
                <span>
                  You have the right to request a copy of the information we hold
                  about you.
                </span>
              </span>
            </li>
            <li class="privacy-policy-li33 list-item">
              <span class="privacy-policy-text187">
                <span class="privacy-policy-text188">
                  Right to Restrict Processing:
                </span>
                <span>
                  You have the right to request restriction of processing your
                  data.
                </span>
              </span>
            </li>
            <li class="privacy-policy-li34 list-item">
              <span class="privacy-policy-text190">
                <span class="privacy-policy-text191">
                  Right to Data Portability:
                  <span v-html="rawlp1w"></span>
                </span>
                <span>
                  You have the right to request the transfer of your data to
                  another data controller.
                </span>
              </span>
            </li>
            <li class="privacy-policy-li35 list-item">
              <span class="privacy-policy-text193">
                <span class="privacy-policy-text194">
                  Right to Object:
                  <span v-html="rawo5fv"></span>
                </span>
                <span>
                  You have the right to object to the processing of your personal
                  data.
                </span>
              </span>
            </li>
            <li class="privacy-policy-li36 list-item">
              <span class="privacy-policy-text196">
                <span class="privacy-policy-text197">
                  Rights Related to Automated Decision Making and Profiling:
                  <span v-html="rawtlfc"></span>
                </span>
                <span>
                  Where processing is necessary for the purposes of legitimate
                  interests pursued by us or a third party, provided that such
                  interests are not overridden by your interests or fundamental
                  rights and freedoms.
                </span>
              </span>
            </li>
          </ul>
          <span class="privacy-policy-text199">
            Please note that the application of these rights varies according to
            the legal basis used to process your data. In certain circumstances,
            we are required to retain copies of information we hold about you by
            other regulations. In this instance, we will not be able to erase or
            modify the data.
          </span>
          <span class="privacy-policy-text200">
            To exercise these rights or if you have any concerns about our use of
            your personal information, please contact the Data Protection Officer
            using the information at the end of this notice.
          </span>
        </div>
        <div class="privacy-policy-content19 thq-flex-column">
          <h4 class="privacy-policy-text201">Security</h4>
          <span class="privacy-policy-text202">
            <span>
              Internet communications, including emails, are not entirely secure.
              We employ encryption methods to protect data during transmission,
              provided that current protocols are used and correct procedures are
              followed for encryption and decryption. We cannot accept any
              responsibility for unauthorised access by a third party or for the
              loss, theft, or modification of data while it is being sent to us.
              For security purposes, we may monitor emails received or issued by
              us.
            </span>
            <br />
          </span>
        </div>
        <div class="privacy-policy-content20 thq-flex-column">
          <h4 class="privacy-policy-text205">Other Websites</h4>
          <span class="privacy-policy-text206">
            <span>
              This privacy notice only applies to our website and application.
              When you use a link to another website, you should read the privacy
              policy of that site.
            </span>
            <br />
          </span>
        </div>
        <div class="privacy-policy-content21 thq-flex-column">
          <h4 class="privacy-policy-text209">Complaints Process</h4>
          <span class="privacy-policy-text210">
            <span>
              If you believe your data protection rights have been violated, we
              encourage you to contact us first to resolve any concerns. You can
              reach us at compliance@plotpoint.co.uk. If you are not satisfied
              with our response, you have the right to lodge a complaint with a
              supervisory authority. In the UK, this is the Information
              Commissioner&apos;s Office (ICO).
            </span>
            <br />
          </span>
        </div>
        <div class="privacy-policy-content22 thq-flex-column">
          <h4 class="privacy-policy-text213">Changes to Our Privacy Notice</h4>
          <span class="privacy-policy-text214">
            <span>
              We keep our privacy notice under regular review. We reserve the
              right to update this privacy notice at any time and we will advise
              you when we make any substantial changes to it.
            </span>
            <br />
          </span>
        </div>
        <div class="privacy-policy-content23 thq-flex-column">
          <h4 class="privacy-policy-text217">How to Contact Us</h4>
          <span class="privacy-policy-text218">
            <span>
              If you have any questions about our privacy notice or the
              information we hold about you, please contact us at
              info@plotpoint.co.uk or by post at the address below:
            </span>
            <br class="privacy-policy-text220" />
            <br />
            <span class="privacy-policy-text222">Data Protection Officer</span>
            <br />
            <span>PlotPoint Limited</span>
            <br />
            <span>70 Baker Street</span>
            <br />
            <span>London, W1U 7DL</span>
            <br />
            <span>Tel: +44 (0) 208 154 7035</span>
            <br />
          </span>
        </div>
        <div class="privacy-policy-content24 thq-flex-column">
          <h4 class="privacy-policy-text232">Last Updated</h4>
          <span class="privacy-policy-text233">
            <span>
              This Privacy Notice was last updated on the 4th of September, 2024
            </span>
            <br />
          </span>
        </div>
      </div>
    </div>
    <app-footer>
      <template v-slot:text>
        <div class="privacy-policy-fragment10">
          <span class="privacy-policy-text236">Learn More</span>
        </div>
      </template>
      <template v-slot:text1>
        <div class="privacy-policy-fragment11">
          <span class="privacy-policy-text237">About us</span>
        </div>
      </template>
      <template v-slot:text2>
        <div class="privacy-policy-fragment12">
          <span class="privacy-policy-text238">Private Credit</span>
        </div>
      </template>
      <template v-slot:text3>
        <div class="privacy-policy-fragment13">
          <span class="privacy-policy-text239">Partner with Us</span>
        </div>
      </template>
      <template v-slot:text5>
        <div class="privacy-policy-fragment14">
          <span class="privacy-policy-text240">Contact</span>
        </div>
      </template>
      <template v-slot:text6>
        <div class="privacy-policy-fragment15">
          <span class="privacy-policy-text241">App</span>
        </div>
      </template>
      <template v-slot:text7>
        <div class="privacy-policy-fragment16">
          <span class="privacy-policy-text242">Download iOS app</span>
        </div>
      </template>
      <template v-slot:text8>
        <div class="privacy-policy-fragment17">
          <span class="privacy-policy-text243">Download Android app</span>
        </div>
      </template>
      <template v-slot:text9>
        <div class="privacy-policy-fragment18">
          <span class="privacy-policy-text244">Originator Portal</span>
        </div>
      </template>
      <template v-slot:link13>
        <div class="privacy-policy-fragment19">
          <span class="privacy-policy-text245">Your Capital is at Risk</span>
        </div>
      </template>
      <template v-slot:text10>
        <div class="privacy-policy-fragment20">
          <span class="privacy-policy-text246">Resources</span>
        </div>
      </template>
      <template v-slot:text11>
        <div class="privacy-policy-fragment21">
          <span class="privacy-policy-text247">Funder Terms and Conditions</span>
        </div>
      </template>
      <template v-slot:text13>
        <div class="privacy-policy-fragment22">
          <span class="privacy-policy-text248">Disclaimer</span>
        </div>
      </template>
      <template v-slot:text14>
        <div class="privacy-policy-fragment23">
          <span class="privacy-policy-text249">Privacy Policy</span>
        </div>
      </template>
      <template v-slot:text15>
        <div class="privacy-policy-fragment24">
          <span class="privacy-policy-text250">Products</span>
        </div>
      </template>
      <template v-slot:content1>
        <div class="privacy-policy-fragment25">
          <span class="privacy-policy-content1">
            <span>Fourth Floor, 70 Baker Street, </span>
            <span>London, W1U 7DL</span>
            <br />
          </span>
        </div>
      </template>
      <template v-slot:content2>
        <div class="privacy-policy-fragment26">
          <span class="privacy-policy-content2">
            <span class="privacy-policy-text254">Phone: </span>
            <a href="tel:+442081547035" class="privacy-policy-link1">+</a>
            <a href="tel:+442081547035" class="privacy-policy-link2">
              44 208 154 7035
            </a>
            <br />
          </span>
        </div>
      </template>
      <template v-slot:content3>
        <div class="privacy-policy-fragment27">
          <span class="privacy-policy-content3">
            <span class="privacy-policy-text256">
              ©2024 PlotPoint Limited. All rights reserved.
            </span>
            <br />
            <span>
              Registered in England &amp; Wales (Reg Number: 15914598).
              <span v-html="rawfgzz"></span>
            </span>
          </span>
        </div>
      </template>
      <template v-slot:contentTitle1>
        <div class="privacy-policy-fragment28">
          <span class="privacy-policy-text259">PlotPoint Limited</span>
        </div>
      </template>
    </app-footer>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import AppFooter from '../components/footer'

export default {
  name: 'PrivacyPolicy',
  props: {},
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      rawhdrc: ' ',
      rawyhp4: ' ',
      raw0qhk: ' ',
      raw1dnx: ' ',
      raw58cv: ' ',
      rawu7cx: ' ',
      rawlp1w: ' ',
      rawo5fv: ' ',
      rawtlfc: ' ',
      rawfgzz: ' ',
    }
  },
  metaInfo: {
    title: 'Privacy Policy - PlotPoint',
    meta: [
      {
        property: 'og:title',
        content: 'Privacy Policy - PlotPoint',
      },
    ],
  },
}
</script>

<style scoped>
.privacy-policy-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
 
.privacy-policy-hero {
  padding-bottom: 0px;
}
 
.privacy-policy-max-width1 {
  align-items: center;
}
 
.privacy-policy-content10 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-layout-space-threeunits);
  align-items: flex-start;
  margin-bottom: var(--dl-layout-space-threeunits);
  flex-direction: column;
}
 
.privacy-policy-subtitle {
  color: var(--dl-color-scheme-white);
  font-size: 14px;
  font-style: normal;
  font-family: "Urbanist";
  font-weight: 800;
  line-height: 1.15;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  text-decoration: none;
}
 
.privacy-policy-title {
  color: var(--dl-color-scheme-white);
  font-size: 52px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 0px;
}
 
.privacy-policy-text101 {
  color: var(--dl-color-scheme-celadon);
}
 
.privacy-policy-image {
  display: none;
  position: relative;
  padding-bottom: var(--dl-layout-space-fourunits);
}
 
.privacy-policy-graphic-top {
  top: 0px;
  right: -170px;
  width: 100%;
  height: 100%;
  position: absolute;
  max-width: 359px;
  max-height: 359px;
  object-fit: contain;
  object-position: center;
}
 
.privacy-policy-layout302 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.privacy-policy-max-width2 {
  gap: var(--dl-layout-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.privacy-policy-content11 {
  gap: 0;
  align-items: flex-start;
  padding-left: var(--dl-layout-space-oneandhalfunits);
  padding-right: var(--dl-layout-space-oneandhalfunits);
}
 
.privacy-policy-text103 {
  font-size: 26px;
  font-style: normal;
  text-align: left;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-layout-space-unit);
}
 
.privacy-policy-text105 {
  color: var(--dl-color-scheme-medgreen);
}
 
.privacy-policy-text106 {
  font-size: 16px;
  text-align: left;
  line-height: 1.5;
  margin-bottom: 0px;
}
 
.privacy-policy-content12 {
  gap: 0;
  align-items: flex-start;
  padding-left: var(--dl-layout-space-oneandhalfunits);
  padding-right: var(--dl-layout-space-oneandhalfunits);
}
 
.privacy-policy-text109 {
  font-size: 18px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
  padding-bottom: var(--dl-layout-space-halfunit);
}
 
.privacy-policy-text110 {
  font-size: 16px;
  text-align: left;
  line-height: 1.5;
  margin-bottom: 0px;
}
 
.privacy-policy-list1 {
  list-style-type: none;
}
 
.privacy-policy-li10 {
  padding-bottom: var(--dl-layout-space-unit);
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
 
.privacy-policy-text113 {
  line-height: 1.5;
}
 
.privacy-policy-text114 {
  font-weight: 700;
}
 
.privacy-policy-li11 {
  list-style-type: disc;
}
 
.privacy-policy-text117 {
  line-height: 1.5;
}
 
.privacy-policy-text118 {
  font-weight: 700;
}
 
.privacy-policy-content13 {
  gap: 0;
  align-items: flex-start;
  padding-left: var(--dl-layout-space-oneandhalfunits);
  padding-right: var(--dl-layout-space-oneandhalfunits);
}
 
.privacy-policy-text122 {
  font-size: 18px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
  padding-bottom: var(--dl-layout-space-halfunit);
}
 
.privacy-policy-text123 {
  font-size: 16px;
  text-align: left;
  line-height: 1.5;
  margin-bottom: 0px;
}
 
.privacy-policy-content14 {
  gap: 0;
  align-items: flex-start;
  padding-left: var(--dl-layout-space-oneandhalfunits);
  padding-right: var(--dl-layout-space-oneandhalfunits);
}
 
.privacy-policy-text126 {
  font-size: 18px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
  padding-bottom: var(--dl-layout-space-halfunit);
}
 
.privacy-policy-text127 {
  font-size: 16px;
  text-align: left;
  line-height: 1.5;
  margin-bottom: 0px;
}
 
.privacy-policy-content15 {
  gap: 0;
  align-items: flex-start;
  padding-left: var(--dl-layout-space-oneandhalfunits);
  padding-right: var(--dl-layout-space-oneandhalfunits);
}
 
.privacy-policy-text130 {
  font-size: 18px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
}
 
.privacy-policy-list2 {
  list-style-type: none;
}
 
.privacy-policy-li12 {
  padding-bottom: var(--dl-layout-space-unit);
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
 
.privacy-policy-text131 {
  line-height: 1.5;
}
 
.privacy-policy-text132 {
  font-weight: 700;
}
 
.privacy-policy-li13 {
  list-style-type: disc;
}
 
.privacy-policy-text134 {
  line-height: 1.5;
}
 
.privacy-policy-text135 {
  font-weight: 700;
}
 
.privacy-policy-content16 {
  gap: 0;
  align-items: flex-start;
  padding-left: var(--dl-layout-space-oneandhalfunits);
  padding-right: var(--dl-layout-space-oneandhalfunits);
}
 
.privacy-policy-text137 {
  font-size: 18px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
}
 
.privacy-policy-list3 {
  list-style-type: none;
}
 
.privacy-policy-li14 {
  padding-bottom: var(--dl-layout-space-unit);
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
 
.privacy-policy-text138 {
  line-height: 1.5;
}
 
.privacy-policy-text140 {
  line-height: 1.5;
}
 
.privacy-policy-li15 {
  padding-bottom: var(--dl-layout-space-unit);
  list-style-type: disc;
}
 
.privacy-policy-text141 {
  line-height: 1.5;
}
 
.privacy-policy-li16 {
  padding-bottom: var(--dl-layout-space-unit);
  list-style-type: disc;
}
 
.privacy-policy-text142 {
  line-height: 1.5;
}
 
.privacy-policy-li17 {
  padding-bottom: var(--dl-layout-space-unit);
  list-style-type: disc;
}
 
.privacy-policy-text143 {
  line-height: 1.5;
}
 
.privacy-policy-li18 {
  padding-bottom: var(--dl-layout-space-unit);
  list-style-type: disc;
}
 
.privacy-policy-text144 {
  line-height: 1.5;
}
 
.privacy-policy-li19 {
  padding-bottom: var(--dl-layout-space-unit);
  list-style-type: disc;
}
 
.privacy-policy-text145 {
  line-height: 1.5;
}
 
.privacy-policy-li20 {
  padding-bottom: var(--dl-layout-space-unit);
  list-style-type: disc;
}
 
.privacy-policy-text146 {
  line-height: 1.5;
}
 
.privacy-policy-li21 {
  padding-bottom: var(--dl-layout-space-unit);
  list-style-type: disc;
}
 
.privacy-policy-text147 {
  line-height: 1.5;
}
 
.privacy-policy-li22 {
  padding-bottom: var(--dl-layout-space-unit);
  list-style-type: disc;
}
 
.privacy-policy-text148 {
  line-height: 1.5;
}
 
.privacy-policy-li23 {
  padding-bottom: var(--dl-layout-space-unit);
  list-style-type: disc;
}
 
.privacy-policy-text149 {
  line-height: 1.5;
}
 
.privacy-policy-li24 {
  padding-bottom: var(--dl-layout-space-unit);
  list-style-type: disc;
}
 
.privacy-policy-text150 {
  line-height: 1.5;
}
 
.privacy-policy-text151 {
  font-size: 16px;
  text-align: left;
  line-height: 1.5;
  margin-bottom: 0px;
}
 
.privacy-policy-content17 {
  gap: 0;
  align-items: flex-start;
  padding-left: var(--dl-layout-space-oneandhalfunits);
  padding-right: var(--dl-layout-space-oneandhalfunits);
}
 
.privacy-policy-text154 {
  font-size: 18px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
  padding-bottom: var(--dl-layout-space-halfunit);
}
 
.privacy-policy-text155 {
  font-size: 16px;
  text-align: left;
  line-height: 1.5;
  margin-bottom: 0px;
}
 
.privacy-policy-list4 {
  list-style-type: none;
}
 
.privacy-policy-li25 {
  padding-bottom: var(--dl-layout-space-unit);
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
 
.privacy-policy-text158 {
  line-height: 1.5;
}
 
.privacy-policy-text159 {
  font-weight: 700;
}
 
.privacy-policy-li26 {
  padding-bottom: var(--dl-layout-space-unit);
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
 
.privacy-policy-text161 {
  line-height: 1.5;
}
 
.privacy-policy-text162 {
  font-weight: 700;
}
 
.privacy-policy-li27 {
  padding-bottom: var(--dl-layout-space-unit);
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
 
.privacy-policy-text164 {
  line-height: 1.5;
}
 
.privacy-policy-text165 {
  font-weight: 700;
}
 
.privacy-policy-li28 {
  padding-bottom: var(--dl-layout-space-unit);
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
 
.privacy-policy-text167 {
  line-height: 1.5;
}
 
.privacy-policy-text168 {
  font-weight: 700;
}
 
.privacy-policy-content18 {
  gap: 0;
  align-items: flex-start;
  padding-left: var(--dl-layout-space-oneandhalfunits);
  padding-right: var(--dl-layout-space-oneandhalfunits);
}
 
.privacy-policy-text170 {
  font-size: 18px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
  padding-bottom: var(--dl-layout-space-halfunit);
}
 
.privacy-policy-text171 {
  font-size: 16px;
  text-align: left;
  line-height: 1.5;
  margin-bottom: 0px;
}
 
.privacy-policy-list5 {
  list-style-type: none;
}
 
.privacy-policy-li29 {
  padding-bottom: var(--dl-layout-space-unit);
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
 
.privacy-policy-text174 {
  line-height: 1.5;
}
 
.privacy-policy-text175 {
  font-weight: 700;
}
 
.privacy-policy-li30 {
  padding-bottom: var(--dl-layout-space-unit);
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
 
.privacy-policy-text177 {
  line-height: 1.5;
}
 
.privacy-policy-text178 {
  font-weight: 700;
}
 
.privacy-policy-li31 {
  padding-bottom: var(--dl-layout-space-unit);
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
 
.privacy-policy-text181 {
  line-height: 1.5;
}
 
.privacy-policy-text182 {
  font-weight: 700;
}
 
.privacy-policy-li32 {
  padding-bottom: var(--dl-layout-space-unit);
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
 
.privacy-policy-text184 {
  line-height: 1.5;
}
 
.privacy-policy-text185 {
  font-weight: 700;
}
 
.privacy-policy-li33 {
  padding-bottom: var(--dl-layout-space-unit);
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
 
.privacy-policy-text187 {
  line-height: 1.5;
}
 
.privacy-policy-text188 {
  font-weight: 700;
}
 
.privacy-policy-li34 {
  padding-bottom: var(--dl-layout-space-unit);
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
 
.privacy-policy-text190 {
  line-height: 1.5;
}
 
.privacy-policy-text191 {
  font-weight: 700;
}
 
.privacy-policy-li35 {
  padding-bottom: var(--dl-layout-space-unit);
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
 
.privacy-policy-text193 {
  line-height: 1.5;
}
 
.privacy-policy-text194 {
  font-weight: 700;
}
 
.privacy-policy-li36 {
  padding-bottom: var(--dl-layout-space-unit);
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
 
.privacy-policy-text196 {
  line-height: 1.5;
}
 
.privacy-policy-text197 {
  font-weight: 700;
}
 
.privacy-policy-text199 {
  font-size: 16px;
  line-height: 1.5;
  padding-bottom: var(--dl-layout-space-unit);
}
 
.privacy-policy-text200 {
  font-size: 16px;
  line-height: 1.5;
}
 
.privacy-policy-content19 {
  gap: 0;
  align-items: flex-start;
  padding-left: var(--dl-layout-space-oneandhalfunits);
  padding-right: var(--dl-layout-space-oneandhalfunits);
}
 
.privacy-policy-text201 {
  font-size: 18px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
  padding-bottom: var(--dl-layout-space-halfunit);
}
 
.privacy-policy-text202 {
  font-size: 16px;
  text-align: left;
  line-height: 1.5;
  margin-bottom: 0px;
}
 
.privacy-policy-content20 {
  gap: 0;
  align-items: flex-start;
  padding-left: var(--dl-layout-space-oneandhalfunits);
  padding-right: var(--dl-layout-space-oneandhalfunits);
}
 
.privacy-policy-text205 {
  font-size: 18px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
}
 
.privacy-policy-text206 {
  font-size: 16px;
  text-align: left;
  line-height: 1.5;
  margin-bottom: 0px;
}
 
.privacy-policy-content21 {
  gap: 0;
  align-items: flex-start;
  padding-left: var(--dl-layout-space-oneandhalfunits);
  padding-right: var(--dl-layout-space-oneandhalfunits);
}
 
.privacy-policy-text209 {
  font-size: 18px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
}
 
.privacy-policy-text210 {
  font-size: 16px;
  text-align: left;
  line-height: 1.5;
  margin-bottom: 0px;
}
 
.privacy-policy-content22 {
  gap: 0;
  align-items: flex-start;
  padding-left: var(--dl-layout-space-oneandhalfunits);
  padding-right: var(--dl-layout-space-oneandhalfunits);
}
 
.privacy-policy-text213 {
  font-size: 18px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
}
 
.privacy-policy-text214 {
  font-size: 16px;
  text-align: left;
  line-height: 1.5;
  margin-bottom: 0px;
}
 
.privacy-policy-content23 {
  gap: 0;
  align-items: flex-start;
  padding-left: var(--dl-layout-space-oneandhalfunits);
  padding-right: var(--dl-layout-space-oneandhalfunits);
}
 
.privacy-policy-text217 {
  font-size: 18px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
}
 
.privacy-policy-text218 {
  font-size: 16px;
  text-align: left;
  line-height: 1.5;
  margin-bottom: 0px;
}
 
.privacy-policy-text220 {
  font-size: 16px;
  line-height: 1.5;
}
 
.privacy-policy-text222 {
  font-weight: 700;
}
 
.privacy-policy-content24 {
  gap: 0;
  align-items: flex-start;
  padding-left: var(--dl-layout-space-oneandhalfunits);
  padding-right: var(--dl-layout-space-oneandhalfunits);
}
 
.privacy-policy-text232 {
  font-size: 18px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
}
 
.privacy-policy-text233 {
  font-size: 16px;
  text-align: left;
  line-height: 1.5;
  margin-bottom: 0px;
}
 
.privacy-policy-fragment10 {
  display: contents;
}
 
.privacy-policy-text236 {
  display: inline-block;
}
 
.privacy-policy-fragment11 {
  display: contents;
}
 
.privacy-policy-text237 {
  display: inline-block;
}
 
.privacy-policy-fragment12 {
  display: contents;
}
 
.privacy-policy-text238 {
  display: inline-block;
}
 
.privacy-policy-fragment13 {
  display: contents;
}
 
.privacy-policy-text239 {
  display: inline-block;
}
 
.privacy-policy-fragment14 {
  display: contents;
}
 
.privacy-policy-text240 {
  display: inline-block;
}
 
.privacy-policy-fragment15 {
  display: contents;
}
 
.privacy-policy-text241 {
  display: inline-block;
}
 
.privacy-policy-fragment16 {
  display: contents;
}
 
.privacy-policy-text242 {
  display: inline-block;
}
 
.privacy-policy-fragment17 {
  display: contents;
}
 
.privacy-policy-text243 {
  display: inline-block;
}
 
.privacy-policy-fragment18 {
  display: contents;
}
 
.privacy-policy-text244 {
  display: inline-block;
}
 
.privacy-policy-fragment19 {
  display: contents;
}
 
.privacy-policy-text245 {
  display: inline-block;
}
 
.privacy-policy-fragment20 {
  display: contents;
}
 
.privacy-policy-text246 {
  display: inline-block;
}
 
.privacy-policy-fragment21 {
  display: contents;
}
 
.privacy-policy-text247 {
  display: inline-block;
}
 
.privacy-policy-fragment22 {
  display: contents;
}
 
.privacy-policy-text248 {
  display: inline-block;
}
 
.privacy-policy-fragment23 {
  display: contents;
}
 
.privacy-policy-text249 {
  display: inline-block;
}
 
.privacy-policy-fragment24 {
  display: contents;
}
 
.privacy-policy-text250 {
  display: inline-block;
}
 
.privacy-policy-fragment25 {
  display: contents;
}
 
.privacy-policy-content1 {
  display: inline-block;
}
 
.privacy-policy-fragment26 {
  display: contents;
}
 
.privacy-policy-content2 {
  display: inline-block;
}
 
.privacy-policy-text254 {
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
}
 
.privacy-policy-link1 {
  text-decoration: underline;
}
 
.privacy-policy-link2 {
  text-decoration: underline;
}
 
.privacy-policy-fragment27 {
  display: contents;
}
 
.privacy-policy-content3 {
  display: inline-block;
}
 
.privacy-policy-text256 {
  font-weight: 700;
}
 
.privacy-policy-fragment28 {
  display: contents;
}
 
.privacy-policy-text259 {
  display: inline-block;
}
 
@media(max-width: 1200px) {
  .privacy-policy-content10 {
    margin-right: 0px;
  }
  .privacy-policy-title {
    font-family: Bricolage Grotesque;
    margin-bottom: 0px;
  }
  .privacy-policy-layout302 {
    padding: var(--dl-layout-space-oneandhalfunits);
  }
}
 
@media(max-width: 991px) {
  .privacy-policy-max-width1 {
    padding-left: var(--dl-layout-space-threeunits);
    padding-right: var(--dl-layout-space-threeunits);
  }
  .privacy-policy-content10 {
    width: 100%;
    margin-right: 0px;
  }
  .privacy-policy-title {
    margin-bottom: 0px;
  }
  .privacy-policy-image {
    padding-bottom: var(--dl-layout-space-threeunits);
  }
  .privacy-policy-text103 {
    text-align: center;
  }
  .privacy-policy-text106 {
    text-align: center;
  }
  .privacy-policy-text109 {
    text-align: center;
  }
  .privacy-policy-text110 {
    text-align: center;
  }
  .privacy-policy-text122 {
    text-align: center;
  }
  .privacy-policy-text123 {
    text-align: center;
  }
  .privacy-policy-text126 {
    text-align: center;
  }
  .privacy-policy-text127 {
    text-align: center;
  }
  .privacy-policy-text130 {
    text-align: center;
  }
  .privacy-policy-text137 {
    text-align: center;
  }
  .privacy-policy-text151 {
    text-align: center;
  }
  .privacy-policy-text154 {
    text-align: center;
  }
  .privacy-policy-text155 {
    text-align: center;
  }
  .privacy-policy-text170 {
    text-align: center;
  }
  .privacy-policy-text171 {
    text-align: center;
  }
  .privacy-policy-text199 {
    text-align: center;
  }
  .privacy-policy-text200 {
    text-align: center;
  }
  .privacy-policy-text201 {
    text-align: center;
  }
  .privacy-policy-text202 {
    text-align: center;
  }
  .privacy-policy-text205 {
    text-align: center;
  }
  .privacy-policy-text206 {
    text-align: center;
  }
  .privacy-policy-text209 {
    text-align: center;
  }
  .privacy-policy-text210 {
    text-align: center;
  }
  .privacy-policy-text213 {
    text-align: center;
  }
  .privacy-policy-text214 {
    text-align: center;
  }
  .privacy-policy-text217 {
    text-align: center;
  }
  .privacy-policy-text218 {
    text-align: center;
  }
  .privacy-policy-text232 {
    text-align: center;
  }
  .privacy-policy-text233 {
    text-align: center;
  }
}
 
@media(max-width: 767px) {
  .privacy-policy-max-width1 {
    padding-left: var(--dl-layout-space-threeunits);
    padding-right: var(--dl-layout-space-threeunits);
    flex-direction: column-reverse;
  }
  .privacy-policy-content10 {
    width: 100%;
    margin-right: 0px;
  }
  .privacy-policy-content11 {
    align-items: flex-start;
  }
  .privacy-policy-text103 {
    text-align: left;
    font-family: Bricolage Grotesque;
  }
  .privacy-policy-text105 {
    color: var(--dl-color-scheme-medgreen);
  }
  .privacy-policy-text106 {
    text-align: left;
  }
  .privacy-policy-content12 {
    align-items: flex-start;
  }
  .privacy-policy-text109 {
    text-align: left;
    font-family: Bricolage Grotesque;
  }
  .privacy-policy-text110 {
    text-align: left;
  }
  .privacy-policy-content13 {
    align-items: flex-start;
  }
  .privacy-policy-text122 {
    text-align: left;
    font-family: Bricolage Grotesque;
  }
  .privacy-policy-text123 {
    text-align: left;
  }
  .privacy-policy-content14 {
    align-items: flex-start;
  }
  .privacy-policy-text126 {
    text-align: left;
    font-family: Bricolage Grotesque;
  }
  .privacy-policy-text127 {
    text-align: left;
  }
  .privacy-policy-content15 {
    align-items: flex-start;
  }
  .privacy-policy-text130 {
    text-align: left;
    font-family: Bricolage Grotesque;
  }
  .privacy-policy-content16 {
    align-items: flex-start;
  }
  .privacy-policy-text137 {
    text-align: left;
    font-family: Bricolage Grotesque;
  }
  .privacy-policy-text151 {
    text-align: left;
  }
  .privacy-policy-content17 {
    align-items: flex-start;
  }
  .privacy-policy-text154 {
    text-align: left;
    font-family: Bricolage Grotesque;
  }
  .privacy-policy-text155 {
    text-align: left;
  }
  .privacy-policy-content18 {
    align-items: flex-start;
  }
  .privacy-policy-text170 {
    text-align: left;
    font-family: Bricolage Grotesque;
  }
  .privacy-policy-text171 {
    text-align: left;
  }
  .privacy-policy-text199 {
    text-align: left;
  }
  .privacy-policy-text200 {
    text-align: left;
  }
  .privacy-policy-content19 {
    align-items: flex-start;
  }
  .privacy-policy-text201 {
    text-align: left;
    font-family: Bricolage Grotesque;
  }
  .privacy-policy-text202 {
    text-align: left;
  }
  .privacy-policy-content20 {
    align-items: flex-start;
  }
  .privacy-policy-text205 {
    text-align: left;
    font-family: Bricolage Grotesque;
  }
  .privacy-policy-text206 {
    text-align: left;
  }
  .privacy-policy-content21 {
    align-items: flex-start;
  }
  .privacy-policy-text209 {
    text-align: left;
    font-family: Bricolage Grotesque;
  }
  .privacy-policy-text210 {
    text-align: left;
  }
  .privacy-policy-content22 {
    align-items: flex-start;
  }
  .privacy-policy-text213 {
    text-align: left;
    font-family: Bricolage Grotesque;
  }
  .privacy-policy-text214 {
    text-align: left;
  }
  .privacy-policy-content23 {
    align-items: flex-start;
  }
  .privacy-policy-text217 {
    text-align: left;
    font-family: Bricolage Grotesque;
  }
  .privacy-policy-text218 {
    text-align: left;
  }
  .privacy-policy-content24 {
    align-items: flex-start;
  }
  .privacy-policy-text232 {
    text-align: left;
    font-family: Bricolage Grotesque;
  }
  .privacy-policy-text233 {
    text-align: left;
  }
}
 
@media(max-width: 479px) {
  .privacy-policy-max-width1 {
    padding-left: var(--dl-layout-space-threeunits);
    padding-right: var(--dl-layout-space-threeunits);
  }
  .privacy-policy-title {
    width: 100%;
    text-align: left;
    font-family: Bricolage Grotesque;
  }
  .privacy-policy-text101 {
    color: var(--dl-color-scheme-celadon);
  }
  .privacy-policy-max-width2 {
    gap: var(--dl-layout-space-oneandhalfunits);
  }
}
</style>
