<template>
  <div class="home-container1">
    <app-header></app-header>
    <div class="home-hero hero-container section-container">
      <div class="home-max-width1 max-width">
        <div class="home-content4">
          <span class="home-subtitle subtitle-top">
            Bridge &amp; Development Loans
          </span>
          <h1 class="home-title">
            <span>
              Asset-backed borrowing
              <span v-html="rawxvxn"></span>
            </span>
            <span class="home-text11">made simple</span>
          </h1>
          <span class="home-description">
            Whether you&apos;re an originator, broker or borrower - We at
            PlotPoint make it hassle free. 
          </span>
          <div class="home-container2">
            <router-link
              to="/products"
              class="home-navlink10 button button-gradient"
            >
              Learn More
            </router-link>
          </div>
        </div>
        <div class="home-image1">
          <img alt="image" src="/home/home2-1200w.jpg" class="home-hero-image1" />
          <img
            alt="image"
            src="/common/union-400h.png"
            class="home-graphic-top"
          />
        </div>
      </div>
    </div>
    <div class="home-layout349 thq-section-padding">
      <div class="home-max-width2 thq-section-max-width">
        <span class="beforeHeading home-text12">
          Tailored Finance Solutions for Every Need
        </span>
        <h1 class="home-text13">
          <span>Our </span>
          <span class="home-text15">Funding Products</span>
        </h1>
        <div class="home-container3 thq-grid-3">
          <div class="home-container4 thq-card">
            <router-link to="/products" class="home-navlink11">
              <img
                alt="Asset-Backed Loans Image Alt Text"
                src="/home/bridgeicon-200h.png"
                class="home-image2 homeproductsimg"
              />
            </router-link>
            <span class="home-text16 beforeHeading">
              ORIGINATORS &amp; iNTERMEDIARIES
            </span>
            <router-link to="/products" class="home-navlink12">
              <h2 class="home-text17 thq-heading-2">Bridging</h2>
            </router-link>
            <span class="home-text18 thq-body-small">
              For developers needing quick, interim funding, our simple bridging
              loans offer flexible short-term capital to cover financing gaps.
            </span>
          </div>
          <div class="home-container5 thq-card">
            <router-link to="/products" class="home-navlink13">
              <img
                alt="Asset-Backed Loans Image Alt Text"
                src="/home/refurbicon-200h.png"
                class="home-image3 homeproductsimg"
              />
            </router-link>
            <span class="home-text19 beforeHeading">
              ORIGINATORS &amp; iNTERMEDIARIES
            </span>
            <router-link to="/products" class="home-navlink14">
              <h2 class="home-text20 thq-heading-2">Refurbishments</h2>
            </router-link>
            <span class="home-text21 thq-body-small">
              For developers revitalising properties, our refurbishment loans
              provide tailored funding for projects of any scale — from light
              updates to heavy transformations.
            </span>
          </div>
          <div class="home-container6 thq-card">
            <router-link to="/products" class="home-navlink15">
              <img
                alt="Asset-Backed Loans Image Alt Text"
                src="/home/developmenticon-200h.png"
                class="home-image4 homeproductsimg"
              />
            </router-link>
            <span class="home-text22 beforeHeading">Intermediaries</span>
            <router-link to="/products" class="home-navlink16">
              <h2 class="home-text23 thq-heading-2">Development</h2>
            </router-link>
            <span class="home-text24 thq-body-small">
              For experienced SME developers with ambitious projects, our
              development loans provide reliable, flexible financing to support
              every phase of your project, from planning to completion.
            </span>
          </div>
        </div>
        <div class="home-container7">
          <router-link
            to="/contact"
            class="home-navlink17 button button-gradient"
          >
            Get a Quote
          </router-link>
          <router-link
            to="/products"
            class="home-navlink18 button button-transparent"
          >
            Learn More
          </router-link>
        </div>
        <span class="home-link13 thq-body-small">
          Loan products are subject to credit approval. Failure to maintain
          repayments may result in repossession of the secured asset. Terms and
          conditions apply.
        </span>
      </div>
    </div>
    <div class="home-section1 section-container">
      <div class="home-max-width3 max-width">
        <div class="home-content5">
          <span class="beforeHeading home-text25">
            for Brokers and originators
          </span>
          <h1 class="home-text26">
            <span>Grow Your </span>
            <span class="home-text28">Business</span>
          </h1>
          <span class="home-text29">
            <span>
              PlotPoint streamlines the process for brokers and originators,
              providing a dedicated point of contact to support you and your
              clients with every type of deal. Get in touch with us and get to
              work on your next deal. 
            </span>
            <br />
          </span>
          <div class="home-container8">
            <router-link
              to="/book-a-call"
              class="home-navlink19 button-secondary button bg-transparent"
            >
              Contact Us Today
            </router-link>
          </div>
        </div>
        <img
          alt="image"
          src="/home/growbusiness-1200w.jpg"
          class="home-hero-image2"
        />
      </div>
    </div>
    <recent-projects rootClassName="recent-projectsroot-class-name">
      <template v-slot:heading>
        <div class="home-fragment10">
          <span class="home-text32">Recent Projects</span>
        </div>
      </template>
    </recent-projects>
    <div class="home-section2 section-container">
      <div class="home-max-width4 max-width">
        <div class="home-banner">
          <span class="home-text33 beforeHeading">get started</span>
          <h1 class="home-text34">A Lender you can rely on</h1>
          <span class="home-text35">
            Simple, transparent and instant access to funds.
          </span>
          <img
            alt="image"
            src="/common/group%2018-1200w.png"
            class="home-image5"
          />
          <div class="home-btns">
            <router-link
              to="/products"
              class="home-navlink20 button button-transparent"
            >
              <span class="home-text36">Learn More</span>
            </router-link>
            <router-link
              to="/book-a-call"
              class="home-navlink21 button button-gradient"
            >
              <span class="home-text37">Get Started</span>
            </router-link>
          </div>
          <img
            alt="image"
            src="/common/group%2018-1200w.png"
            class="home-image6"
          />
        </div>
      </div>
    </div>
    <app-footer>
      <template v-slot:text>
        <div class="home-fragment11">
          <span class="home-text38">Learn More</span>
        </div>
      </template>
      <template v-slot:text1>
        <div class="home-fragment12">
          <span class="home-text39">About us</span>
        </div>
      </template>
      <template v-slot:text2>
        <div class="home-fragment13">
          <span class="home-text40">Private Credit</span>
        </div>
      </template>
      <template v-slot:text3>
        <div class="home-fragment14">
          <span class="home-text41">Partner with Us</span>
        </div>
      </template>
      <template v-slot:text5>
        <div class="home-fragment15">
          <span class="home-text42">Contact</span>
        </div>
      </template>
      <template v-slot:text6>
        <div class="home-fragment16"><span class="home-text43">App</span></div>
      </template>
      <template v-slot:text7>
        <div class="home-fragment17">
          <span class="home-text44">Download iOS app</span>
        </div>
      </template>
      <template v-slot:text8>
        <div class="home-fragment18">
          <span class="home-text45">Download Android app</span>
        </div>
      </template>
      <template v-slot:text9>
        <div class="home-fragment19">
          <span class="home-text46">Originator Portal</span>
        </div>
      </template>
      <template v-slot:link13>
        <div class="home-fragment20">
          <span class="home-text47">Your Capital is at Risk</span>
        </div>
      </template>
      <template v-slot:text10>
        <div class="home-fragment21">
          <span class="home-text48">Resources</span>
        </div>
      </template>
      <template v-slot:text11>
        <div class="home-fragment22">
          <span class="home-text49">Funder Terms and Conditions</span>
        </div>
      </template>
      <template v-slot:text13>
        <div class="home-fragment23">
          <span class="home-text50">Disclaimer</span>
        </div>
      </template>
      <template v-slot:text14>
        <div class="home-fragment24">
          <span class="home-text51">Privacy Policy</span>
        </div>
      </template>
      <template v-slot:text15>
        <div class="home-fragment25">
          <span class="home-text52">Products</span>
        </div>
      </template>
      <template v-slot:content1>
        <div class="home-fragment26">
          <span class="home-content1">
            <span>Fourth Floor, 70 Baker Street, </span>
            <span>London, W1U 7DL</span>
            <br />
          </span>
        </div>
      </template>
      <template v-slot:content2>
        <div class="home-fragment27">
          <span class="home-content2">
            <span class="home-text56">Phone: </span>
            <a href="tel:+442081547035" class="home-link1">+</a>
            <a href="tel:+442081547035" class="home-link2">44 208 154 7035</a>
            <br />
          </span>
        </div>
      </template>
      <template v-slot:content3>
        <div class="home-fragment28">
          <span class="home-content3">
            <span class="home-text58">
              ©2024 PlotPoint Limited. All rights reserved.
            </span>
            <br />
            <span>
              Registered in England &amp; Wales (Reg Number: 15914598).
              <span v-html="raw59t3"></span>
            </span>
          </span>
        </div>
      </template>
      <template v-slot:contentTitle1>
        <div class="home-fragment29">
          <span class="home-text61">PlotPoint Limited</span>
        </div>
      </template>
    </app-footer>
  </div>
</template>

<script>
import AppHeader from '../components/header'
import RecentProjects from '../components/recent-projects'
import AppFooter from '../components/footer'

export default {
  name: 'Home',
  props: {},
  components: {
    AppHeader,
    RecentProjects,
    AppFooter,
  },
  data() {
    return {
      rawxvxn: ' ',
      raw59t3: ' ',
    }
  },
  metaInfo: {
    title: 'Bridging and Development Finance - PlotPoint',
    meta: [
      {
        name: 'description',
        content:
          'Discover PlotPoint for hassle-free property financing. Enjoy fast, tailored funding and expert support to bring your project to life.',
      },
      {
        property: 'og:title',
        content: 'Bridging and Development Finance - PlotPoint',
      },
      {
        property: 'og:description',
        content:
          'Discover PlotPoint for hassle-free property financing. Enjoy fast, tailored funding and expert support to bring your project to life.',
      },
    ],
  },
}
</script>

<style scoped>
.home-container1 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
 
.home-hero {
  padding-bottom: 0px;
}
 
.home-max-width1 {
  align-items: flex-start;
}
 
.home-content4 {
  flex: 0 0 auto;
  width: 45%;
  display: flex;
  margin-top: var(--dl-layout-space-fourunits);
  align-items: flex-start;
  margin-right: var(--dl-layout-space-twounits);
  margin-bottom: var(--dl-layout-space-fourunits);
  flex-direction: column;
}
 
.home-subtitle {
  color: var(--dl-color-scheme-white);
  text-transform: uppercase;
}
 
.home-title {
  color: var(--dl-color-scheme-white);
  font-size: 52px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 600;
  line-height: 1;
  margin-bottom: var(--dl-layout-space-unit);
}
 
.home-text11 {
  color: var(--dl-color-scheme-celadon);
}
 
.home-description {
  color: var(--dl-color-scheme-white);
  font-size: 18px;
  font-family: "Bricolage Grotesque";
  line-height: 1.5;
  padding-bottom: var(--dl-layout-space-twounits);
}
 
.home-container2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: row;
}
 
.home-navlink10 {
  text-decoration: none;
}
 
.home-image1 {
  position: relative;
  padding-bottom: 0px;
}
 
.home-hero-image1 {
  flex: 1;
  max-width: 550px;
  object-fit: cover;
  border-radius: 48px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
 
.home-graphic-top {
  top: 2px;
  right: -174px;
  width: 100%;
  height: 100%;
  position: absolute;
  max-width: 359px;
  max-height: 359px;
  object-fit: contain;
  object-position: center;
}
 
.home-layout349 {
  gap: var(--dl-layout-space-twounits);
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  padding-top: var(--dl-layout-space-fourunits);
  flex-direction: row;
  padding-bottom: var(--dl-layout-space-fourunits);
  justify-content: center;
}
 
.home-max-width2 {
  gap: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.home-text13 {
  font-size: 42px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-layout-space-unit);
}
 
.home-text15 {
  color: var(--dl-color-scheme-medgreen);
}
 
.home-container3 {
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}
 
.home-container4 {
  gap: 0;
  flex: 1;
  height: auto;
  display: flex;
  align-self: flex-start;
  box-shadow: 2px 2px 4px 0px #d4d4d4;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-accent1);
}
 
.home-navlink11 {
  display: contents;
}
 
.home-image2 {
  text-decoration: none;
}
 
.home-text16 {
  margin-top: var(--dl-layout-space-unit);
  text-align: center;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
}
 
.home-navlink12 {
  display: contents;
}
 
.home-text17 {
  text-align: center;
  text-decoration: none;
}
 
.home-text18 {
  text-align: center;
}
 
.home-container5 {
  gap: 0;
  flex: 1;
  height: auto;
  display: flex;
  align-self: flex-start;
  box-shadow: 2px 2px 4px 0px #d4d4d4;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-accent1);
}
 
.home-navlink13 {
  display: contents;
}
 
.home-image3 {
  width: var(--dl-layout-size-large);
  height: var(--dl-layout-size-large);
  object-fit: cover;
  text-decoration: none;
}
 
.home-text19 {
  margin-top: var(--dl-layout-space-unit);
  text-align: center;
}
 
.home-navlink14 {
  display: contents;
}
 
.home-text20 {
  text-align: center;
  text-decoration: none;
}
 
.home-text21 {
  text-align: center;
}
 
.home-container6 {
  gap: 0;
  flex: 1;
  height: auto;
  display: flex;
  align-self: flex-start;
  box-shadow: 2px 2px 4px 0px #d4d4d4;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-accent1);
}
 
.home-navlink15 {
  display: contents;
}
 
.home-image4 {
  width: var(--dl-layout-size-large);
  height: var(--dl-layout-size-large);
  object-fit: cover;
  text-decoration: none;
}
 
.home-text22 {
  margin-top: var(--dl-layout-space-unit);
  text-align: center;
}
 
.home-navlink16 {
  display: contents;
}
 
.home-text23 {
  text-align: center;
  text-decoration: none;
}
 
.home-text24 {
  text-align: center;
}
 
.home-container7 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: stretch;
  padding-top: var(--dl-layout-space-unit);
  flex-direction: row;
  padding-bottom: var(--dl-layout-space-unit);
  justify-content: center;
}
 
.home-navlink17 {
  transition: 0.3s;
  text-decoration: none;
}
 
.home-navlink17:hover {
  fill: var(--dl-color-scheme-green100);
  color: var(--dl-color-scheme-green100);
  border-color: var(--dl-color-scheme-green80);
}
 
.home-navlink18 {
  fill: var(--dl-color-scheme-green80);
  color: var(--dl-color-scheme-green80);
  border-color: var(--dl-color-scheme-green80);
  text-decoration: none;
}
 
.home-link13 {
  font-size: 12px;
  font-style: normal;
  text-align: center;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
}
 
.home-section1 {
  padding-top: var(--dl-layout-space-fourunits);
  padding-bottom: var(--dl-layout-space-fourunits);
  background-color: var(--dl-color-scheme-honeydew);
}
 
.home-max-width3 {
  align-items: stretch;
}
 
.home-content5 {
  width: 60%;
  display: flex;
  margin-right: var(--dl-layout-space-twounits);
  flex-direction: column;
  justify-content: center;
}
 
.home-text26 {
  font-size: 42px;
  font-style: normal;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: var(--dl-layout-space-unit);
}
 
.home-text28 {
  color: var(--dl-color-scheme-medgreen);
}
 
.home-text29 {
  font-size: 16px;
  line-height: 1.5;
}
 
.home-container8 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-layout-space-unit);
  align-items: stretch;
  margin-bottom: var(--dl-layout-space-unit);
  flex-direction: row;
}
 
.home-navlink19 {
  font-family: "Bricolage Grotesque";
}
 
.home-hero-image2 {
  width: 600px;
  height: 300px;
  object-fit: cover;
  border-radius: 48px;
}
 
.home-fragment10 {
  display: contents;
}
 
.home-text32 {
  display: inline-block;
}
 
.home-section2 {
  position: relative;
  padding-top: var(--dl-layout-space-twounits);
  padding-bottom: var(--dl-layout-space-twounits);
}
 
.home-max-width4 {
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.home-banner {
  clip: auto;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-layout-space-fourunits);
  position: relative;
  align-items: center;
  border-radius: 48px;
  flex-direction: column;
  background-color: var(--dl-color-scheme-green100);
}
 
.home-text33 {
  color: var(--dl-color-scheme-honeydew);
  margin-bottom: var(--dl-layout-space-halfunit);
}
 
.home-text34 {
  color: var(--dl-color-scheme-white);
  z-index: 100;
  font-size: 42px;
  font-style: normal;
  text-align: center;
  font-family: "Bricolage Grotesque";
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: var(--dl-layout-space-halfunit);
}
 
.home-text35 {
  fill: var(--dl-color-scheme-white);
  color: var(--dl-color-scheme-white);
  width: auto;
  z-index: 100;
  text-align: center;
  padding-left: 0px;
  margin-bottom: var(--dl-layout-space-twounits);
  padding-right: 0px;
}
 
.home-image5 {
  top: 33px;
  right: -2px;
  width: 195px;
  height: 275px;
  opacity: 0.4;
  z-index: 1;
  position: absolute;
  object-fit: contain;
}
 
.home-btns {
  flex: 0 0 auto;
  display: flex;
  align-items: stretch;
  flex-direction: row;
}
 
.home-navlink20 {
  font-size: 16px;
  text-decoration: none;
}
 
.home-navlink21 {
  text-align: center;
  margin-left: var(--dl-layout-space-twounits);
  text-decoration: none;
}
 
.home-image6 {
  top: 19px;
  left: 2px;
  width: 195px;
  height: 275px;
  opacity: 0.4;
  z-index: 1;
  position: absolute;
  transform: rotate(180deg);
  object-fit: contain;
}
 
.home-fragment11 {
  display: contents;
}
 
.home-text38 {
  display: inline-block;
}
 
.home-fragment12 {
  display: contents;
}
 
.home-text39 {
  display: inline-block;
}
 
.home-fragment13 {
  display: contents;
}
 
.home-text40 {
  display: inline-block;
}
 
.home-fragment14 {
  display: contents;
}
 
.home-text41 {
  display: inline-block;
}
 
.home-fragment15 {
  display: contents;
}
 
.home-text42 {
  display: inline-block;
}
 
.home-fragment16 {
  display: contents;
}
 
.home-text43 {
  display: inline-block;
}
 
.home-fragment17 {
  display: contents;
}
 
.home-text44 {
  display: inline-block;
}
 
.home-fragment18 {
  display: contents;
}
 
.home-text45 {
  display: inline-block;
}
 
.home-fragment19 {
  display: contents;
}
 
.home-text46 {
  display: inline-block;
}
 
.home-fragment20 {
  display: contents;
}
 
.home-text47 {
  display: inline-block;
}
 
.home-fragment21 {
  display: contents;
}
 
.home-text48 {
  display: inline-block;
}
 
.home-fragment22 {
  display: contents;
}
 
.home-text49 {
  display: inline-block;
}
 
.home-fragment23 {
  display: contents;
}
 
.home-text50 {
  display: inline-block;
}
 
.home-fragment24 {
  display: contents;
}
 
.home-text51 {
  display: inline-block;
}
 
.home-fragment25 {
  display: contents;
}
 
.home-text52 {
  display: inline-block;
}
 
.home-fragment26 {
  display: contents;
}
 
.home-content1 {
  display: inline-block;
}
 
.home-fragment27 {
  display: contents;
}
 
.home-content2 {
  display: inline-block;
}
 
.home-text56 {
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
}
 
.home-link1 {
  text-decoration: underline;
}
 
.home-link2 {
  text-decoration: underline;
}
 
.home-fragment28 {
  display: contents;
}
 
.home-content3 {
  display: inline-block;
}
 
.home-text58 {
  font-weight: 700;
}
 
.home-fragment29 {
  display: contents;
}
 
.home-text61 {
  display: inline-block;
}
 
@media(max-width: 1200px) {
  .home-text11 {
    color: var(--dl-color-scheme-celadon);
  }
  .home-graphic-top {
    top: -31px;
    right: -166px;
  }
  .home-text15 {
    color: var(--dl-color-scheme-celadon);
  }
  .home-text26 {
    font-family: Bricolage Grotesque;
  }
  .home-text28 {
    color: var(--dl-color-scheme-medgreen);
  }
}
 
@media(max-width: 991px) {
  .home-content4 {
    width: 50%;
    margin-right: var(--dl-layout-space-unit);
  }
  .home-image1 {
    padding-bottom: var(--dl-layout-space-threeunits);
  }
  .home-hero-image1 {
    margin-bottom: 0px;
  }
  .home-layout349 {
    padding-top: var(--dl-layout-space-threeunits);
    padding-left: var(--dl-layout-space-fourunits);
    padding-right: var(--dl-layout-space-fourunits);
    padding-bottom: var(--dl-layout-space-threeunits);
  }
  .home-max-width2 {
    gap: 0nit;
    padding-left: var(--dl-layout-space-sixunits);
    padding-right: var(--dl-layout-space-sixunits);
    flex-direction: column;
  }
  .home-text12 {
    text-align: center;
  }
  .home-text13 {
    text-align: center;
  }
  .home-container3 {
    grid-template-columns: 1fr;
  }
  .home-text16 {
    width: auto;
    align-self: center;
  }
  .home-text19 {
    width: auto;
    align-self: center;
  }
  .home-text22 {
    width: auto;
    align-self: center;
  }
  .home-section1 {
    align-items: center;
  }
  .home-max-width3 {
    display: flex;
    align-items: flex-start;
    padding-left: var(--dl-layout-space-sixunits);
    padding-right: var(--dl-layout-space-sixunits);
    flex-direction: row;
  }
  .home-content5 {
    width: 100%;
    align-items: flex-start;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    justify-content: center;
  }
  .home-text25 {
    width: auto;
    align-self: flex-start;
    text-align: left;
  }
  .home-text26 {
    text-align: left;
    margin-bottom: var(--dl-layout-space-halfunit);
  }
  .home-text29 {
    text-align: left;
  }
  .home-container8 {
    justify-content: flex-start;
  }
  .home-hero-image2 {
    width: 350px;
    height: 250px;
    margin-left: 0px;
    margin-bottom: 0px;
  }
  .home-max-width4 {
    flex-direction: column;
  }
}
 
@media(max-width: 767px) {
  .home-max-width1 {
    flex-direction: column;
  }
  .home-content4 {
    width: 100%;
    margin-top: var(--dl-layout-space-threeunits);
    margin-right: 0px;
    margin-bottom: var(--dl-layout-space-unit);
  }
  .home-description {
    padding-bottom: var(--dl-layout-space-unit);
  }
  .home-image1 {
    width: 100%;
    padding-bottom: 0px;
  }
  .home-hero-image1 {
    width: 100%;
    max-width: 100%;
    max-height: 400px;
    margin-right: 0px;
    object-position: top;
    border-top-left-radius: 48px;
    border-top-right-radius: 48px;
  }
  .home-graphic-top {
    display: none;
  }
  .home-layout349 {
    padding-top: var(--dl-layout-space-twounits);
    padding-bottom: var(--dl-layout-space-twounits);
  }
  .home-max-width2 {
    gap: 0;
    padding-left: var(--dl-layout-space-twounits);
    padding-right: var(--dl-layout-space-twounits);
  }
  .home-text13 {
    font-family: Bricolage Grotesque;
  }
  .home-text15 {
    color: var(--dl-color-scheme-celadon);
  }
  .home-container3 {
    grid-template-columns: 1fr;
  }
  .home-container4 {
    width: 100%;
  }
  .home-container5 {
    width: 100%;
  }
  .home-container6 {
    width: 100%;
  }
  .home-link13 {
    font-style: normal;
    font-family: Bricolage Grotesque;
    font-weight: 700;
  }
  .home-section1 {
    padding-top: var(--dl-layout-space-threeunits);
    padding-bottom: var(--dl-layout-space-threeunits);
  }
  .home-max-width3 {
    padding-left: var(--dl-layout-space-fourunits);
    padding-right: var(--dl-layout-space-fourunits);
    flex-direction: column;
  }
  .home-content5 {
    width: 100%;
    margin-right: 0px;
  }
  .home-hero-image2 {
    width: 100%;
    height: var(--dl-layout-size-xxlarge);
  }
  .home-banner {
    padding: var(--dl-layout-space-twounits);
  }
  .home-image5 {
    top: 40px;
    right: 1px;
  }
  .home-navlink21 {
    z-index: 100;
  }
  .home-image6 {
    top: 2px;
    right: -166px;
  }
}
 
@media(max-width: 479px) {
  .home-max-width1 {
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-content4 {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: var(--dl-layout-space-oneandhalfunits);
    margin-bottom: 0px;
    padding-right: var(--dl-layout-space-oneandhalfunits);
    padding-bottom: var(--dl-layout-space-unit);
  }
  .home-subtitle {
    padding-top: var(--dl-layout-space-threeunits);
  }
  .home-container2 {
    padding-bottom: var(--dl-layout-space-twounits);
  }
  .home-image1 {
    overflow: hidden;
    max-width: 100%;
    min-width: 100%;
    max-height: 500px;
    padding-bottom: 0px;
  }
  .home-graphic-top {
    top: -125px;
    right: -178px;
    display: none;
  }
  .home-layout349 {
    padding-left: var(--dl-layout-space-twounits);
    padding-right: var(--dl-layout-space-twounits);
  }
  .home-max-width2 {
    gap: 0;
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-container3 {
    display: grid;
    grid-template-columns: 1fr;
  }
  .home-section1 {
    padding-top: var(--dl-layout-space-twounits);
    padding-bottom: var(--dl-layout-space-twounits);
  }
  .home-max-width3 {
    padding-left: var(--dl-layout-space-twounits);
    padding-right: var(--dl-layout-space-twounits);
  }
  .home-content5 {
    margin-bottom: 0px;
  }
  .home-text26 {
    font-family: Bricolage Grotesque;
  }
  .home-text28 {
    color: var(--dl-color-scheme-medgreen);
  }
  .home-container8 {
    padding-bottom: var(--dl-layout-space-unit);
  }
  .home-hero-image2 {
    height: var(--dl-layout-size-xxlarge);
  }
  .home-banner {
    padding-top: var(--dl-layout-space-twounits);
    padding-left: var(--dl-layout-space-twounits);
    padding-right: var(--dl-layout-space-twounits);
    padding-bottom: var(--dl-layout-space-twounits);
  }
  .home-text35 {
    line-height: 1.4;
  }
  .home-image5 {
    top: 143px;
    right: 1px;
  }
  .home-btns {
    gap: var(--dl-layout-space-unit);
    flex-direction: column;
  }
  .home-navlink20 {
    z-index: 100;
    text-align: center;
    font-family: Bricolage Grotesque;
  }
  .home-text36 {
    font-size: 16px;
    font-style: normal;
    font-family: Bricolage Grotesque;
    font-weight: 700;
  }
  .home-navlink21 {
    z-index: 100;
    font-size: 16px;
    font-family: Bricolage Grotesque;
  }
  .home-text37 {
    font-style: normal;
    font-family: Bricolage Grotesque;
    font-weight: 600;
  }
}
</style>
